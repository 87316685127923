// lodash replacements
// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
// @ts-nocheck

export const isEmpty = (obj: any): boolean =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;

export const groupBy = (arr: any[], key = 'length') =>
  arr.reduce((r, v, i, a, k = key) => ((r[k] || (r[k] = [])).push(v), r), {});

export const isEqual = (obj1, obj2) => {
  /**
   * More accurately check the type of a JavaScript object
   * @param  {Object} obj The object
   * @return {String}     The object type
   */
  function getType(obj): string {
    return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
  }

  function areArraysEqual(): boolean {
    // Check length
    if (obj1.length !== obj2.length) return false;

    // Check each item in the array
    for (let i = 0; i < obj1.length; i++) {
      if (!isEqual(obj1[i], obj2[i])) return false;
    }

    // If no errors, return true
    return true;
  }

  function areObjectsEqual(): boolean {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    // Check each item in the object
    for (const key in obj1) {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (!isEqual(obj1[key], obj2[key])) return false;
      }
    }

    // If no errors, return true
    return true;
  }

  function areFunctionsEqual(): boolean {
    return obj1.toString() === obj2.toString();
  }

  function arePrimativesEqual(): boolean {
    return obj1 === obj2;
  }

  // Get the object type
  const type = getType(obj1);

  // If the two items are not the same type, return false
  if (type !== getType(obj2)) return false;

  // Compare based on type
  if (type === 'array') return areArraysEqual();
  if (type === 'object') return areObjectsEqual();
  if (type === 'function') return areFunctionsEqual();
  return arePrimativesEqual();
};

export const uniqWith = (arr, comparator) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  const cb =
    typeof comparator === 'function' ? comparator : (o) => o[comparator];

  const pickedObjects = arr
    .filter((item) => item)
    .reduce((map, item) => {
      const key = cb(item);

      if (!key) {
        return map;
      }

      return map.has(key) ? map : map.set(key, item);
    }, new Map())
    .values();

  return [...pickedObjects];
};
