import styled from 'styled-components'

import { BaseSize, ButtonResetStyle, Color, getPx, TextSize } from '../../style'

const filledButtonStyle = `
  background-color: ${Color.OffBlack};
  color: white;

  font-weight: 600;
  font-size: ${getPx(TextSize.Normal)};
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;

  border-radius: ${getPx(BaseSize.Radius)};
  border: 1px solid ${Color.OffBlack};

  padding-top: ${getPx(BaseSize.Baseline)};
  padding-bottom: ${getPx(BaseSize.Baseline)};
  padding-left: ${getPx(BaseSize.Grid)};
  padding-right: ${getPx(BaseSize.Grid)};

  transition: all 300ms ease-out;

  &:hover {
    box-shadow: 0 10px 9px -7px rgba(0, 0, 0, 0.11);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0 0 0 transparent;
    transform: translateY(0px);
  }

  &:disabled {
    background: #a2a2a2;
    border-color: #a2a2a2;
    &:hover,
    &:active {
      transform: none;
      box-shadow: none;
    }
  }
`

export const StyledTiaryButtonFilled = styled.button`
  ${ButtonResetStyle};
  ${filledButtonStyle};
`

export const StyledTiaryButtonOutline = styled.button`
  display: inline-block;
  padding: ${getPx(BaseSize.Grid)};
  color: ${Color.ActionPurple};
  border: 1px solid ${Color.ActionPurple};
  margin-top: ${getPx(BaseSize.Grid, 1 / 2)};
  text-transform: uppercase;
  font-weight: 600;
  font-size: ${getPx(TextSize.Normal)};
  letter-spacing: 2.5px;
  border-radius: ${getPx(BaseSize.Radius)};
  transition: all 250ms ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 9px -7px rgba(0, 0, 0, 0.11);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 0 0 0 0 transparent;
    transform: translateY(0px);
  }
`

export const StyledTiaryButtonMinimal = styled.button`
  ${ButtonResetStyle};
  display: block;
  color: ${Color.OffBlack};
  font-weight: 600;
  font-size: ${getPx(TextSize.Normal)};
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  transition: opacity 250ms ease;

  &:hover {
    opacity: 0.5;
  }
`
