const DEFAULT_BASE_URL = 'https://tiary.imgix.net';

interface BuildImgixUrlArgs {
  baseUrl?: string;
  width?: number;
  height?: number;
  fit?: ImgixFitMode;
  auto?: string[];
  autoCompress?: boolean;
  autoFormat?: boolean;
  pixelRatio?: number;
  backgroundColor?: string;
  sourceRectangleRegion?: string;
}

export enum ImgixFitMode {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  FaceArea = 'facearea',
  Fill = 'fill',
  FillMax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale',
}

export const getImgixUrl = (
  path: string,
  {
    baseUrl = DEFAULT_BASE_URL,
    width,
    height,
    fit,
    auto,
    autoCompress = true,
    autoFormat = true,
    pixelRatio,
    backgroundColor,
    sourceRectangleRegion,
  }: BuildImgixUrlArgs
) => {
  const params = [];

  if (width) {
    params.push(`w=${width}`);
  }
  if (height) {
    params.push(`h=${height}`);
  }
  if (fit) {
    params.push(`fit=${fit}`);
  }
  if (pixelRatio) {
    params.push(`dpr=${pixelRatio}`);
  }
  if (backgroundColor) {
    params.push(`bg=${backgroundColor}`);
  }
  if (sourceRectangleRegion) {
    params.push(`rect=${sourceRectangleRegion}`);
  }

  // Automatic features (e.g., compression and format) let ImgIX choose the best
  // option based on the device and browser.

  const autoParams = auto || [];
  if (autoCompress && !autoParams.includes('compress')) {
    autoParams.push('compress');
  }
  if (autoFormat && !autoParams.includes('format')) {
    autoParams.push('format');
  }
  params.push(`auto=${autoParams.join(',')}`);

  let url = `${baseUrl}${path}`;
  if (params.length) {
    url = `${url}?${params.join('&')}`;
  }
  return url;
};
