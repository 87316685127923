import { ProductOffset, ProductRenderConstraints } from './product';

/**
 * Materials
 * ---
 * A material is the most basic unit of a product. Each meaningful variation of
 * a stone, metal, finding, etc is given a uniqe material ID and its own doc
 * in the database. This makes expected combinations explicit and solves for a
 * number of previous issues with things like missing prices.
 */

export enum MaterialType {
  Stone = 'STONE',
  Metal = 'METAL',
  Chain = 'CHAIN',
  Finding = 'FINDING',
}

export interface StoneMaterial {
  id: string; // e.g., "UVK19KsGZDuL9rpUhZYv"
  material: MaterialType.Stone;
  size: string; // e.g., "1_2MM"
  stone: string; // e.g., "TOURMALINE"
  color: string; // e.g., "PINK"
  quality: string; // e.g., "GENUINE"

  // for legacy support of the TiaryProduct and ProductConfiguration types
  type: string; // e.g., "TOURMALINE_PINK"
}

export interface MetalMaterial {
  id: string; // e.g., "UVK19KsGZDuL9rpUhZYv"
  material: MaterialType.Metal;
  metal: string; // e.g., "GOLD"
  color: string; // e.g., "ROSE"
  quality: string; // e.g., "KARAT_18"

  // for legacy support of the TiaryProduct and ProductConfiguration types
  type: string; // e.g., "GOLD_ROSE"
}

export interface ChainMaterial {
  id: string; // e.g., "UVK19KsGZDuL9rpUhZYv"
  material: MaterialType.Chain;
  size: string; // e.g., 18_INCHES
  style: string; // e.g., "ROLO"
  metal: string; // e.g., "GOLD"
  quality: string; // e.g., "KARAT_10"
}

export interface FindingMaterial {
  id: string; // e.g., "UVK19KsGZDuL9rpUhZYv"
  material: MaterialType.Finding;
  finding: string; // e.g., "CLASP_LOBSTER"
  metal: string; // e.g., "GOLD"
  quality: string; // e.g., KARAT_14
}

export interface MaterialGroup {
  id: string;
  name: string;
  type: MaterialType;
  materials: string[];
}

export type TiaryMaterial =
  | StoneMaterial
  | MetalMaterial
  | ChainMaterial
  | FindingMaterial;

/**
 * Manufacturer
 * ---
 * Since we have multiple manufacturers and dynamic pricing in the database, we
 * must store the unique costs associated with a manufactuerer in a doc.
 */

interface MaterialCostMap {
  [materialId: string]: number;
}

export interface ManufacturerShared {
  name: string;
  costs: {
    materials: MaterialCostMap;
    findings: MaterialCostMap;
    assembly: number;
    stoneSettings: {
      [type: string]: number;
    };
    finishings: {
      [metal: string]: {
        [quality: string]: { minWeight: number; cost: number }[];
      };
    };
    rhodium: {
      [quality: string]: { minWeight: number; cost: number }[];
    };
  };
}

export interface Manufacturer extends ManufacturerShared {
  id: string;
}

/**
 * Pricing
 * ---
 * This document is primarily used by the dashboard and contains the common
 * fixed costs associated with production and pricing calculations.
 */

export interface DashboardMargins {
  base: number;
  stoneQuality?: {
    [quality: string]: number;
  };
  metalQuality?: {
    [quality: string]: number;
  };
}

export interface DashboardPricing {
  margin: DashboardMargins;
  manufacturerOfReference: string;
  packaging: number;
  pickAndPack: number;
  rhodiumFactor: number;
  shipping: number;
}

/**
 * Dashboard Products
 * ---
 * These products contain a lot of information that is not consumer-facing.
 * They are used as reference when communicating with a manufacturer or when
 * constructing a consumer product.
 */

export interface DashboardProductFinding {
  type: string;
  quantity: number;
}

export interface DashboardProductFindingAdditionalChain
  extends DashboardProductFinding {
  reducedRate: number;
}

export type DashboardProductRenders = {
  SHADOW?: string;
} & {
  [option: string]: {
    [type: string]: string;
  };
};

export interface DashboardProduct {
  id: string;
  basePrice?: number;
  options: DashboardProductOption[];
  findings: DashboardProductFinding[];
  renders?: DashboardProductRenders;
  renderConstraints?: ProductRenderConstraints;
  pricing?: {
    [optionName: string]: { price: number }[];
  };
  margins?: {
    base?: number;
  };
  //adding support for offsets
  offsets?:ProductOffset[]
}

export enum MaterialEntryType {
  Group = 'GROUP',
  Material = 'MATERIAL',
}

export enum ProductOptionType {
  Metal = 'METAL',
  Stone = 'STONE',
  Chain = 'CHAIN',
  Text = 'TEXT',
  Engraving = 'ENGRAVING',
  BraceletSize = 'BRACELET_SIZE',
  RingSize = 'RING_SIZE',
  Quantity = 'QUANTITY',
  Grouped = 'GROUPED',

}

interface DashboardOptionMeta {
  label: string;
  name: string;
}

// OPTION: METAL or CHAIN

export interface DashboardProductChainOption extends DashboardOptionMeta {
  type: ProductOptionType.Chain;
  values: string | string[]; // either a single grouping ID or an array of sizes
}

export interface DashboardProductMetalOption extends DashboardOptionMeta {
  type: ProductOptionType.Metal;
  renders?: { [type: string]: string };
  weights: {
    [metal: string]: {
      [quality: string]: number;
    };
  };
  values: string | string[]; // either a single grouping ID or an array of material IDs
}

// OPTION: STONE

export interface DashboardProductStoneSize {
  quantity: number;
  size: string;
}

export interface DashboardStoneType {
  stone: string;
  color?: string;
  quality: string;
}

export interface DashboardProductStoneOption extends DashboardOptionMeta {
  type: ProductOptionType.Stone;
  renders?: { [type: string]: string };
  stoneSetting?: string;
  values: {
    types: DashboardStoneType[] | string; // either a single grouping ID or an array of material IDs
    sizes: DashboardProductStoneSize[];
  };
}

export interface DashboardProductQuantityOption extends DashboardOptionMeta {
  type: ProductOptionType.Quantity;
  values: string[];
}

// OPTION: TEXT or ENGRAVING

export interface DashboardProductStringOption extends DashboardOptionMeta {
  type: ProductOptionType.Engraving | ProductOptionType.Text;
  minLength?: number;
  maxLength?: number;
  regex?: string;
}

export interface DashboardProductTextOption
  extends DashboardProductStringOption {
  type: ProductOptionType.Text;
  required?: boolean;
}

// OPTION: SIZE

export interface DashboardProductSizeOption extends DashboardOptionMeta {
  type: ProductOptionType.BraceletSize | ProductOptionType.RingSize;
  values: string[];
}

export interface DashboardProductGroupedOption extends DashboardOptionMeta {
  type: ProductOptionType.Grouped;
  values: { fieldName: string }[];
}

// ALL OPTIONS (union type)

export type DashboardProductOption =
  | DashboardProductMetalOption
  | DashboardProductChainOption
  | DashboardProductStoneOption
  | DashboardProductStringOption
  | DashboardProductTextOption
  | DashboardProductSizeOption
  | DashboardProductQuantityOption
  | DashboardProductGroupedOption;

/**
 * Utils Bag
 * ---
 * This is used for dashboard functions. It's mostly for passing context that is
 * often shared so that we can minimize the amount of data requests required.
 */

export interface DashboardUtilsBag {
  materials: TiaryMaterial[];
  groupings: MaterialGroup[];
  pricing: DashboardPricing;
  localize?: (term: string) => string;
}
