import { customPricePlugin } from '../../plugins/customPrice';
import { OptionCostCalculator, ProductCostLineItem } from '../../types';

export const getCostForNoOption: OptionCostCalculator = (
  { product, manufacturer, configuration },
  bag
) => {
  let lineItems: ProductCostLineItem[] = [];

  const plugins = [customPricePlugin];

  plugins.forEach((applyPlugin) => {
    lineItems = applyPlugin(
      {
        product,
        lineItems,
        manufacturer,
        configuration,
      },
      bag
    );
  });

  return lineItems;
};
