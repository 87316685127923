import sumBy from 'lodash/sumBy';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import {
  ProductConfiguration,
  Manufacturer,
  DashboardProduct,
  ProductOptionType,
  DashboardUtilsBag,
} from '../../types';

import {
  ProductCostResult,
  OptionCostCalculator,
  ProductCostPlugin,
} from './types';
import { getCostForMetalOption } from './options/metal';
import { getCostForStoneOption } from './options/stone';
import { getCostForChainOption } from './options/chain';
import { getCostForNoOption } from './options/none';
import { quantityCostPlugin } from './plugins/quantity';

export * from './options';
export * from './plugins';
export * from './types';
export * from './goldman';


const lineItemTypeSortOrder = [
  ProductOptionType.Metal,
  'FINISHING',
  ProductOptionType.Chain,
  ProductOptionType.Stone,
  ProductOptionType.Engraving,
  'FINDING',
  'ASSEMBLY',
  ProductOptionType.Quantity,
];

interface GetCostForProductArgs {
  product: DashboardProduct;
  configuration: ProductConfiguration;
  manufacturer: Manufacturer;
}

interface GetCostForProductOptions {
  detailed?: boolean; // if it should include detailed pricing line items. This was created to hide the fake rhodium charge sent to the manufacturer
}

const optionCalculators: { [key: string]: OptionCostCalculator } = {
  [ProductOptionType.Metal]: getCostForMetalOption,
  [ProductOptionType.Stone]: getCostForStoneOption,
  [ProductOptionType.Chain]: getCostForChainOption,
};

export const getCostForProduct = (
  { product, configuration, manufacturer }: GetCostForProductArgs,
  bag: DashboardUtilsBag,
  options: GetCostForProductOptions
): ProductCostResult => {

 

  let lineItems = flatten(
    product.options.map((option) =>
      optionCalculators[option.type]
        ? optionCalculators[option.type](
            {
              option,
              product,
              manufacturer,
              configuration,
            },
            bag
          )
        : []
    )
  );

  // HACK: Custom call the getCostForNoOption plugin
  if (product.options.length === 0) {
    lineItems = [
      ...lineItems,
      ...getCostForNoOption(
        {
          option: undefined as any,
          product,
          manufacturer,
          configuration,
        },
        bag
      ),
    ];
  }

  // These are plugins not dependent on the product option (e.x stone, metal, etc)
  // e.x quantity applies to all products, regardless of the metal or stone
  const globalPlugins: ProductCostPlugin[] = [quantityCostPlugin];

  globalPlugins.forEach((applyPlugin) => {
    lineItems = applyPlugin(
      {
        product,
        lineItems,
        manufacturer,
        configuration,
      },
      bag
    );
  });

  // keep all the line items with "fake" if we have the detailed option enabled
  const filteredLineItems = options.detailed
    ? lineItems
    : lineItems.filter((lineItem) => !lineItem.type.includes('FAKE_'));

  //const filteredLineItems = options.detailed ? lineItems : lineItems;

  const sortedLineItems = sortBy(filteredLineItems, (o) => {
    const optionIndex = lineItemTypeSortOrder.indexOf(o.type);
    // sort items not found at the end
    return optionIndex === -1 ? 999 : optionIndex;
  });

  return find(sortedLineItems, ({ type }) => type === 'ERROR')
    ? {
        status: 'ERROR',
        items: sortedLineItems,
      }
    : {
        status: 'SUCCESS',
        cost: sumBy(sortedLineItems, 'cost'),
        items: sortedLineItems,
      };
};
