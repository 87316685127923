import { CountryCode, CurrencyCode, Locale } from "../types"

import { conversionRates } from "../config"

interface DescribePriceOptions {
  currencyCode: CurrencyCode
  countryCode: CountryCode
  removeDecimals?: boolean
  convert?: boolean
}

export const describePrice = (
  value: number,
  {
    currencyCode,
    countryCode,
    removeDecimals,
    convert = true,
  }: DescribePriceOptions,
) => {
  const conversionRate = convert
    ? conversionRates[currencyCode as CurrencyCode]
    : 1
    
  const convertedPrice =  new Intl.NumberFormat(Locale[countryCode], {
    currency: currencyCode,
    maximumFractionDigits: removeDecimals ? 0 : 2,
    minimumFractionDigits: removeDecimals ? 0 : 2,
    style: "currency",
  }).format(value * conversionRate)

  return convertedPrice

  
}
