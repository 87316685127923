import * as React from 'react';
import { CustomizerFieldProps, TextCustomizerField } from '../fields';
import { FormikValues } from 'formik';

export interface CustomizerFieldListProps<
  ProductType = any,
  ProductOptionType = any,
  OptionValueType = any
> {
  className?: string;
  product: ProductType;
  values: FormikValues;
  errors: any;
  setFieldValue: (name: string, value: OptionValueType) => void;
  getField?: (
    option: ProductOptionType,
    allOptions: ProductOptionType[]
  ) => React.FunctionComponent<
    CustomizerFieldProps<ProductType, ProductOptionType>
  > | null;
  sortOptions?: (options: ProductOptionType[]) => ProductOptionType[];
}

export const CustomizerFieldList: React.FunctionComponent<CustomizerFieldListProps> = ({
  className = '',
  product,
  values,
  errors,
  setFieldValue,
  getField = () => TextCustomizerField,
  sortOptions = (options: { name: string }[]) =>
    options.sort((a, b) => (a.name > b.name ? 1 : -1)),
}) => {
  const sortedOptions = sortOptions(product.options);

  const renderField = (option: any): React.ReactNode => {
    const Field = getField(option, product.options);
    return Field ? (
      <Field
        key={option.name}
        product={product}
        option={option}
        values={values}
        setFieldValue={setFieldValue}
        error={errors[option.name]}
      />
    ) : null;
  };

  return (
    <div className={`tiary-customizer__fields ${className}`}>
      {sortedOptions.map(renderField)}
    </div>
  );
};
