import React from 'react';
import { CountryContext } from '../../contexts';

interface TiaryPriceProps<CountryCode = string, CurrencyCode = string> {
  price?: number;
  className?: string;
  countryCode?: CountryCode;
  currencyCode?: CurrencyCode;
}

export const TiaryPrice: React.FunctionComponent<TiaryPriceProps> = ({
  price = 0,
  className,
  countryCode,
  currencyCode,
}) => {
  if (!countryCode || !currencyCode) {
    throw new Error('TiaryPrice requires a country code and currency code.');
  }

  const { formatPrice } = React.useContext(CountryContext);

  return (
    <div className={className}>
      {formatPrice(price, { countryCode, currencyCode, removeDecimals: true })}
    </div>
  );
};
