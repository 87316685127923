import { PriceRule } from "../types"

interface ApplyPromoCodeArgs {
  promoCode: PriceRule
  subtotal: number
}

// Returns the amount of the DISCOUNT after applying a promo code
export const applyPromoCode = async ({
  promoCode,
  subtotal,
}: ApplyPromoCodeArgs) => {
  try {
    let currentSubtotal = subtotal
    let discountAmount = 0

    if (promoCode.percent) {
      // If the promo code has a percentage (e.g., 0.3 representing 30% off)
      // then we apply it before the promo code credit is consumed
      discountAmount = currentSubtotal * promoCode.percent
      currentSubtotal -= discountAmount
    }

    if (promoCode.fixedAmount) {
      // A fixed amount is like a credit. The fixed amount used is the amount of
      // that credit that has already been consumed on previous orders.
      const { fixedAmountUsed = 0, fixedAmount } = promoCode
      const fixedAmountRemaining = fixedAmount - fixedAmountUsed

      // return the remaining credit or the current subtotal, whichever is greater
      discountAmount +=
        fixedAmountRemaining < currentSubtotal
          ? fixedAmountRemaining
          : currentSubtotal
      currentSubtotal -= discountAmount
    }

    return discountAmount
  } catch (error) {
    return 0
  }
}
