export enum BaseSize {
  Text = 14,
  Baseline = 18,
  IdealParagraphWidth = 430,
  Grid = 18,
  Radius = 2,
}

export enum TextSize {
  VerySmall = 11,
  Small = 12,
  Normal = BaseSize.Text,
  Longform = 16,
  Large = 24,
  veryLarge = 36,
}

export enum Color {
  PalePurple = '#948296',
  OffBlack = '#242424',
  OffWhite = '#FAF8FB',
  LightGrey = '#eeebef',
  ActionPurple = '#948296',
  ActionPurpleHover = '#8A668E',
}
