import { StoneConfig, StoneQuality, StoneType } from "../types"

const STONE_TYPES = {
  AMETHYST: "Amethyst",
  AQUAMARINE: "Aquamarine",
  CITRINE: "Citrine",
  DIAMOND_BLACK: "Black Diamond",
  DIAMOND_WHITE: "White Diamond",
  EMERALD: "Emerald",
  GARNET: "Garnet",
  PERIDOT: "Peridot",
  QUARTZ_SMOKY: "Smoky Quartz",
  RAINBOW: "Rainbow",
  RUBY: "Ruby",
  SAPPHIRE_BLUE: "Blue Sapphire",
  SAPPHIRE_PINK: "Pink Sapphire",
  TOPAZ_BLUE: "Blue Topaz",
  TOPAZ_LONDON_BLUE: "London Blue Topaz",
  TOPAZ_SMOKY: "Smoky Topaz",
  TOURMALINE_PINK: "Pink Tourmaline",
  LAPIS: "Lapis Lazuli",
  ONYX_BLACK: "Black Onyx",
  TURQUOISE: "Turqouise",
}

const STONE_QUALITIES = {
  SIMULATED: "Simulated",
  GENUINE: "Natural",
}

export const describeStoneType = (type: StoneType) => STONE_TYPES[type] || ""
export const describeStoneQuality = (quality: StoneQuality) =>
  STONE_QUALITIES[quality] || ""

const defaultStoneTemplate = ({ type, quality }: StoneConfig) =>
  `${quality} ${type}`

export const describeStone = (
  stoneObj: StoneConfig,
  { template = defaultStoneTemplate } = {},
) => {
  if (!stoneObj) {
    return ""
  }
  const type = describeStoneType(stoneObj.type) as StoneType
  const quality = describeStoneQuality(stoneObj.quality) as StoneQuality
  return template({ quality, type })
}
