import { OrderProduct } from "./order"

export enum RendererLayoutMode {
  Fixed = "FIXED",
  Responsive = "RESPONSIVE",
  Contain = "CONTAIN",
  Native = "NATIVE",
}

export interface RendererFixedLayout {
  mode: RendererLayoutMode.Fixed
  height: number
  width: number
  focalPointX?: number
  focalPointY?: number
  zoomFactor?: number
}

export interface RendererResponsiveLayout {
  mode: RendererLayoutMode.Responsive
  height: number
  width: number
  focalPointX?: number
  focalPointY?: number
  zoomFactor?: number
}

export interface RendererNativeLayout {
  mode: RendererLayoutMode.Native
  scale?: number
}

export interface RendererContainLayout {
  mode: RendererLayoutMode.Contain
  height: number
  width: number
}

export declare type RendererLayout =
  | RendererFixedLayout
  | RendererNativeLayout
  | RendererResponsiveLayout
  | RendererContainLayout

export enum RenderMode {
  Edit = "EDIT",
  Preview = "PREVIEW",
}

// RENDER MODE
// Product groups can have multiple render "modes"
// which define how each render is stacked and layed out.

export enum RenderGroupMode {
  Stack = "STACK",
  DragAndDrop = "DRAG_AND_DROP",
  Layers = "LAYERS",
}

export enum FloatDirection {
  Vertical = "VERTICAL",
  Horizontal = "HORIZONTAL",
}

export enum ProductType {
  Group = "GROUP",
}

// PRODUCT GROUP TYPE
// Product groups can be composed of other product groups.

export interface ProductGroupStage {
  x?: number
  y?: number
  width?: number
  height?: number
  direction?: FloatDirection
  dragAndDrop?: boolean
}

export interface ProductGroup {
  id: string
  mode: RenderGroupMode
  name: string
  type: ProductType.Group
  min?: number
  max?: number
  contents: Array<OrderProduct | ProductGroup>
  stage?: ProductGroupStage
  layout: RendererLayout
}
