import * as React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import iconChevronDown from '../../../assets/icons/icon-chevron-down-svg';
import iconChevronUp from '../../../assets/icons/icon-chevron-up-svg';

const StyledCustomizerFieldExpandTemplate = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .tiary-customizer__field-template {
    width: 100%;
    &__section--main {
      flex: 1;
    }

    &__section--expand {
      width: 100%;
    }

    &__section--after {
      display: flex;
      align-items: center;
      &_label {
        margin-right: 5px;
        font-size: 11px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }

      &_icon {
        height: 16px;
        width: 16px;
      }
    }
  }
`;

const StyledCustomizerFieldExpandInnerContent = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: inherit;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  text-align: start;
`;

export interface CustomizerFieldExpandTemplateProps {
  className?: string;
  label?: string;
  description?: string;
  error?: string;
  beforeContents?: React.ReactNode;
  expand?: boolean;
  toggleExpand: () => void;
}

const animationProps = {
  initial: {
    opacity: 0,
    height: 0,
    marginTop: 0,
  },
  animate: {
    opacity: 1,
    height: 'auto',
    marginTop: 9,
  },
  exit: {
    opacity: 0,
    height: 0,
    marginTop: 0,
  },
};

export const CustomizerFieldExpandTemplate: React.FunctionComponent<CustomizerFieldExpandTemplateProps> = ({
  className = 'tiary-customizer__field-template',
  label,
  description,
  error,
  beforeContents,
  expand,
  toggleExpand,
  children,
}) => (
  <StyledCustomizerFieldExpandTemplate className={`${className}`}>
    <StyledCustomizerFieldExpandInnerContent onClick={toggleExpand}>
      {beforeContents && (
        <div className="tiary-customizer__field-template__section--before">
          {beforeContents}
        </div>
      )}
      <div className="tiary-customizer__field-template__section--main">
        {label && (
          <div className="tiary-customizer__field-template__label">{label}</div>
        )}
        {description && (
          <div className="tiary-customizer__material-field__description">
            {description}
          </div>
        )}
        {error && (
          <div className="tiary-customizer__field-template__error">{error}</div>
        )}
      </div>
      <div className="tiary-customizer__field-template__section--after">
        <span className="tiary-customizer__field-template__section--after_label">
          {expand ? 'hide' : 'show'}
        </span>
        <img
          className="tiary-customizer__field-template__section--after_icon"
          src={expand ? iconChevronUp : iconChevronDown}
          alt=""
        />
      </div>
    </StyledCustomizerFieldExpandInnerContent>
    <AnimatePresence exitBeforeEnter={true} initial={false}>
      {expand && (
        <motion.div
          className="tiary-customizer__field-template__section--expand"
          {...animationProps}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  </StyledCustomizerFieldExpandTemplate>
);
