import styled from 'styled-components'

interface StyledTiaryRendererProps {
  responsive: boolean
  height?: number
  width?: number
  backgroundHex?: string
}

export const StyledTiaryRenderer = styled.div<StyledTiaryRendererProps>`
  height: ${({ responsive, height }) => (responsive ? 0 : `${height}px`)};
  width: ${({ responsive, width }) => (responsive ? 'auto' : `${width}px`)};
  padding-top: ${({ responsive, height, width }) =>
    responsive && height && width ? `${(height / width) * 100}%` : 0};
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${({ backgroundHex }) => backgroundHex || 'transparent'};
`
