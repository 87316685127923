// Finds the largest rectangle that will fit a given set of dimensions while
// positioning the extents of the images relative to the position of a given
// "cursor".

// This is valuable for normalizing images according to a fixed point,
// especially before performing other operations on them in e.g., Imgix.
// The output is a rectangle that could be used to crop the image.

// For example, we may have a set of product photos that we want to display
// in a grid. In this case we want to normalize the ground plane and center
// point of each photo. We determine a cursor for each photo at the center of
// the ground plane (separately, either programmatically or in a tool like
// PhotoShop). Providing the image's unique cursor and native dimensions,
// along with an X ratio of 0.5 (horizontally centered) and a Y ratio of 0.8
// (80% product, 20% ground) we can give each image a consistent position with
// minimal cropping.

//                | <- Cursor X
//     -----------|---
//     |          |  |
//     |          |  |
//     |          |  |
//     -----------+-----  <- Cursor Y
//     |          |  |
//     |          |  |
//     -----------|---

const dimension = {
  X: 'X',
  Y: 'Y',
}

class Rect {
  x0 = 0
  x1 = 1
  y0 = 0
  y1 = 1

  constructor({ x0 = 0, x1 = 1, y0 = 0, y1 = 1 }) {
    this.x0 = x0
    this.x1 = x1
    this.y0 = y0
    this.y1 = y1
  }

  getWidth = () => Math.abs(this.x1 - this.x0)
  getHeight = () => Math.abs(this.y1 - this.y0)
  getDimensions = () => ({
    width: this.getWidth(),
    height: this.getHeight(),
  })
  getAspectRatio = () => this.getWidth() / this.getHeight()

  getPointAtFocusX = (percent: number) => this.getWidth() * percent + this.x0
  getPointAtFocusY = (percent: number) => this.getHeight() * percent + this.y0
  getPointAtFocus = ({ x, y }: { x: number; y: number }) => ({
    x: this.getPointAtFocusX(x),
    y: this.getPointAtFocusY(y),
  })

  getBoundOverlap = (bounds: {
    x0: number
    x1: number
    y0: number
    y1: number
  }) => {
    const overlap = { top: 0, right: 0, bottom: 0, left: 0 }
    if (this.x0 < bounds.x0) {
      overlap.left = Math.ceil(bounds.x0 - this.x0)
    }
    if (this.x1 > bounds.x1) {
      overlap.right = Math.ceil(this.x1 - bounds.x1)
    }
    if (this.y0 < bounds.y0) {
      overlap.top = Math.ceil(bounds.y0 - this.y0)
    }
    if (this.y1 > bounds.y1) {
      overlap.bottom = Math.ceil(this.y1 - bounds.y1)
    }
    return overlap
  }

  toString = () =>
    [this.x0, this.y0, this.getWidth(), this.getHeight()].join(',')

  constrainToBounds = (bounds: {
    x0: number
    x1: number
    y0: number
    y1: number
  }) => {
    if (this.x0 < bounds.x0) {
      this.x0 = bounds.x0
    }
    if (this.x1 > bounds.x1) {
      this.x1 = bounds.x1
    }
    if (this.y0 < bounds.y0) {
      this.y0 = bounds.y0
    }
    if (this.y1 > bounds.y1) {
      this.y1 = bounds.y1
    }
    return this
  }

  translateX = (offset: number) => {
    this.x0 += offset
    this.x1 += offset
    return this
  }
  translateY = (offset: number) => {
    this.y0 += offset
    this.y1 += offset
    return this
  }
  translate = ({ x, y }: { x: number; y: number }) =>
    this.translateX(x).translateY(y)

  trimWidth = (width_target: number) => {
    this.x1 -= this.getWidth() - width_target
    return this
  }
  trimHeight = (height_target: number) => {
    this.y1 -= this.getHeight() - height_target
    return this
  }

  cropX = (width_target: number) => {
    if (width_target < 0) {
      throw new Error('cant crop more than the full width')
    }
    const width_diff = this.getWidth() - width_target
    this.x1 -= width_diff
    return this.translateX(width_diff / 2)
  }
  cropY = (height_target: number) => {
    if (height_target < 0) {
      throw new Error('cant crop more than the full height')
    }
    const height_diff = this.getHeight() - height_target
    this.y1 -= height_diff
    return this.translateY(height_diff / 2)
  }
  crop = (x: number, y: number) => this.cropX(x).cropY(y)
  cropToAspectRatio = (ar: number) => {
    const { width, height } = this.getDimensions()
    const ar_native = this.getAspectRatio()
    const cropDimension = ar_native > ar ? dimension.X : dimension.Y
    if (cropDimension === dimension.X) {
      const width_target = height * ar
      return this.cropX(width_target)
    } else {
      const height_target = width * (1 / ar)
      return this.cropY(height_target)
    }
  }

  zoom = (zoomFactor: number) => {
    const { width, height } = this.getDimensions()
    const width_target = width / zoomFactor
    const height_target = height / zoomFactor
    return this.crop(width_target, height_target)
  }

  snapToPixel = () => {
    this.x0 = Math.floor(this.x0)
    this.x1 = Math.floor(this.x1)
    this.y0 = Math.floor(this.y0)
    this.y1 = Math.floor(this.y1)
    return this
  }
}

export default ({
  aspectRatio = 1,
  zoom = 1,
  focalPoint = { x: 0, y: 0 },
  focus = { x: 0.5, y: 0.5 },
}) => {
  const constraints = new Rect({ x0: 0, x1: 2000, y0: 0, y1: 2000 })

  // 1. crop to desired aspect ratio
  constraints.cropToAspectRatio(aspectRatio)

  // // 2. zoom with inset
  constraints.zoom(zoom)

  // 3. translate to match point on main image
  const focalPoint_current = constraints.getPointAtFocus(focus)
  constraints.translate({
    x: focalPoint.x - focalPoint_current.x,
    y: focalPoint.y - focalPoint_current.y,
  })

  // 4. calculate any margins
  const imageBounds = { x0: 0, x1: 2000, y0: 0, y1: 2000 }
  const margins = constraints.getBoundOverlap(imageBounds)

  // remove margins from width
  if (margins.left >= margins.right) {
    const diff = margins.left - margins.right
    const sum = margins.left + margins.right
    constraints.trimWidth(constraints.getWidth() - sum)
    constraints.translateX(diff)
  } else {
    const diff = margins.right - margins.left
    const sum = margins.right + margins.left
    constraints.trimWidth(constraints.getWidth() - sum)
    constraints.translateX(-1 * diff)
  }

  // remove margins from height
  if (margins.top >= margins.bottom) {
    const diff = margins.top - margins.bottom
    const sum = margins.top + margins.bottom
    constraints.trimHeight(constraints.getHeight() - sum)
    constraints.translateY(diff)
  } else {
    const diff = margins.bottom - margins.top
    const sum = margins.bottom + margins.top
    constraints.trimHeight(constraints.getHeight() - sum)
    constraints.translateY(-1 * diff)
  }

  return constraints.snapToPixel().toString()
}
