import { Address, CountryCode, StripeShippingAddress } from '../types';

export const addressFromStripeData = ({
  payerEmail,
  payerName,
  shippingAddress,
}: {
  payerName: string;
  payerEmail: string;
  shippingAddress: StripeShippingAddress;
}): { address: Address; email: string } => ({
  email: payerEmail,
  address: {
    firstName: payerName.split(' ')[0],
    lastName: payerName.split(' ').slice(1).join(' '),
    company: shippingAddress.organization,
    streetAddress: shippingAddress.addressLine.join(' '),
    locality: shippingAddress.city,
    region: shippingAddress.region,
    country: shippingAddress.country as CountryCode,
    postalCode: shippingAddress.postalCode,
    phone: shippingAddress.phone,
  },
});
