import { PriceRule } from '../types';

import {
  tableRowData,
  tableValueDateFromFirebase,
  tableValuePercent,
} from './table';

const fields: any[] = [
  { label: 'ID', value: 'id' },
  { label: 'Code', value: 'code' },
  { label: 'Description', value: 'description' },
  { label: 'Discount Percent', value: tableValuePercent('percent') },
  { label: 'Discount Amount', value: 'fixedAmount' },
  { label: 'Amount Used', value: 'fixedAmountUsed' },
  { label: 'Start Date', value: tableValueDateFromFirebase('startDate') },
  { label: 'End Date', value: tableValueDateFromFirebase('endDate') },
  { label: 'Primary', value: 'isPrimary' },
  { label: 'Used', value: 'used' },
  { label: 'Available', value: 'available' },
  { label: 'Single Use', value: 'isSingleUse' },
];

export const priceRuleColumnLabels = fields.map(({ label }) => label);

export const tableDataFromPriceRules = (priceRules: PriceRule[]) =>
  priceRules.map((priceRule) => tableRowData(priceRule, fields));

export const csvFromPriceRules = () => {
  console.warn(
    'csvFromPriceRules function has been removed from tiary-shared.'
  );
};
