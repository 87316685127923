import * as React from 'react';
import Styled from 'styled-components';
import { RendererLayout, RendererLayoutMode } from '../../types/renderer';

import { getUrlForLayer } from './imgix';

interface LayerDivProps {
  url: string;
  imageRendering?: string;
  backgroundHex?: string;
}

const LayerDiv = Styled.div<LayerDivProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: block;
  image-rendering: ${({ imageRendering = 'auto' }) => imageRendering};
  background-color: ${({ backgroundHex }) => backgroundHex || 'transparent'};
  background-image: ${({ url }) => `url(${url})`};
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
`;

interface LayerImageProps {
  height?: number;
  width?: number;
  imageRendering?: string;
  backgroundHex?: string;
  positionStatic?: boolean;
}

const LayerImage = Styled.img<LayerImageProps>`
  position: ${({ positionStatic }) => (positionStatic ? 'static' : 'absolute')};
  top: 0;
  left: 0;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  display: block;
  image-rendering: ${({ imageRendering = 'auto' }) => imageRendering};
  background-color: ${({ backgroundHex }) => backgroundHex || 'transparent'};
`;

interface LayerProps {
  path: string;
  preload?: boolean;
  hide?: boolean;
  backgroundHex?: string;
  onLoad?: () => void;
  onError?: () => void;
  layout: RendererLayout;
  opacity?: number;
  imageRendering?: string;
  renderConstraints: {
    [key: string]: any;
  };
  positionStatic?: boolean;
  imgixBaseUrl?: string;
}

interface LayerSize {
  height: number;
  width: number;
}

const Layer: React.FunctionComponent<LayerProps> = ({
  path,
  preload = true,
  hide,
  backgroundHex,
  imgixBaseUrl,
  layout,
  onLoad,
  onError,
  opacity = 1,
  imageRendering,
  renderConstraints,
  positionStatic,
}) => {
  const [url, setUrl] = React.useState<string | null>(null);
  const [naturalSize, setNaturalSize] = React.useState<LayerSize | null>(null);
  const image = React.useRef<HTMLImageElement | null>(null);

  React.useEffect(() => {
    const nextUrl = getUrlForLayer({
      path,
      layout,
      backgroundHex,
      renderConstraints,
      baseUrl: imgixBaseUrl,
    });

    if (!nextUrl || nextUrl === url) {
      return;
    }

    if (!preload) {
      setUrl(nextUrl);
    } else {
      preloadImage(nextUrl);
    }

    return clearPreloaders;
  }, [path, layout, backgroundHex, renderConstraints]);

  const preloadImage = (preloadUrl: string) => {
    setUrl(null);
    const nextImage = new Image();
    nextImage.onload = () => {
      setUrl(preloadUrl);
      if (onLoad) {
        onLoad();
      }
      setNaturalSize({
        height: nextImage.naturalHeight,
        width: nextImage.naturalWidth,
      });
    };
    nextImage.onerror = () => {
      setUrl(preloadUrl);
      if (onError) {
        onError();
      }
    };
    nextImage.src = preloadUrl;
    image.current = nextImage;
  };

  const clearPreloaders = () => {
    if (image.current) {
      image.current.onload = () => {};
      image.current.onerror = () => {};
    }
  };

  // return null if hidden or loading
  if (!url || hide) {
    return null;
  }

  if (layout.mode === RendererLayoutMode.Native && naturalSize) {
    return (
      <LayerImage
        height={Math.round(naturalSize.height * (layout.scale || 1))}
        width={Math.round(naturalSize.width * (layout.scale || 1))}
        backgroundHex={backgroundHex}
        src={url}
        style={{ opacity }}
        imageRendering={imageRendering}
        positionStatic={positionStatic}
      />
    );
  }

  return (
    <LayerDiv
      backgroundHex={backgroundHex}
      url={url}
      style={{ opacity }}
      imageRendering={imageRendering}
    />
  );
};

export default Layer;
