import { Address } from "../types"

interface AddressOptions {
  separator?: string
}

export const describeAddress = (
  {
    firstName,
    lastName,
    company,
    streetAddress,
    locality,
    region,
    country,
    postalCode,
  }: Address,
  { separator }: AddressOptions = {},
) => {
  const parts = [
    `${firstName} ${lastName}`,
    company,
    streetAddress,
    `${locality}, ${region}, ${country}`,
    postalCode,
  ]
  if (separator) {
    return parts.join(separator)
  }
  return parts
}
