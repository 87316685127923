export enum OrderStatusCode {
  InProduction = "IN_PRODUCTION",
  Wax = "WAX",
  CastingAndStoneSetting = "CASTING_AND_STONE_SETTING",
  Polishing = "POLISHING",
  QualityControl = "QUALITY_CONTROL",
  Shipped = "SHIPPED",
  Processing = "PROCESSING",
  Cancelled = "CANCELLED",
  Complete = "COMPLETE",
}
