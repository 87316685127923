import { ProductCostPlugin, ProductCostLineItem } from '../../types';
import sumBy from 'lodash/sumBy';
import { ProductOptionType } from '../../../../types';

export const quantityCostPlugin: ProductCostPlugin = ({
  product,
  lineItems,
  configuration,
}) => {
  const doesHaveQuantityOption = product.options.find(
    (productOption) => productOption.type === ProductOptionType.Quantity
  );

  const quantityValue = doesHaveQuantityOption
    ? Number(configuration[ProductOptionType.Quantity])
    : 1;

  const isEarring = product.id ? product.id.charAt(2) === 'E' : false;

  if (doesHaveQuantityOption && quantityValue > 0) {
    if (isEarring && quantityValue === 1) {
      // Get the cost of the item for the manufacturer by going through
      // each line item and summing the cost
      const priceOfItem = sumBy(lineItems, 'cost');

      const priceOfSingle = -(priceOfItem / 2);

      const totalQuantityLineItem: ProductCostLineItem = {
        label: `Quantity`,
        type: 'QUANTITY',
        description: `Earrings are priced as 2. Single selected so dividing by 2.`,
        cost: priceOfSingle,
        quantity: 1,
        unitCost: priceOfSingle,
      };

      return [...lineItems, totalQuantityLineItem];
    } else if (!isEarring) {
      // Get the cost of the item for the manufacturer by going through
      // each line item and summing the cost
      const priceOfItem = sumBy(lineItems, 'cost');
      // subtract one, since pricing wise we already account for the product
      const quantityToMultiply = quantityValue - 1;

      const totalQuantityLineItem: ProductCostLineItem = {
        label: `Quantity`,
        type: 'QUANTITY',
        description: `Costs of the additional items`,
        cost: priceOfItem * quantityToMultiply,
        quantity: quantityValue,
        unitCost: priceOfItem,
      };

      return [...lineItems, totalQuantityLineItem];
    }
  }

  return lineItems;
};
