import { AddressParts } from '../types/index';
import { MixpanelProfile } from '../types/mixpanel';

export const mixpanelProfileFromAddressParts = (
  addressParts: AddressParts = {},
  email: string
): MixpanelProfile => {
  const profile: MixpanelProfile = {};

  if (addressParts.firstName) {
    profile.$name = `${addressParts.firstName} ${addressParts.lastName}`;
  }
  if (addressParts.firstName) {
    profile.$firstName = addressParts.firstName;
  }
  if (addressParts.lastName) {
    profile.$lastName = addressParts.lastName;
  }

  if (email) {
    profile.$email = email;
  }

  if (addressParts.locality) {
    profile.$city = addressParts.locality;
  }
  if (addressParts.region) {
    profile.$region = addressParts.region;
  }
  if (addressParts.country) {
    profile.$country_code = addressParts.country;
  }

  return profile;
};
