import find from 'lodash/find';
import compact from 'lodash/compact';
import get from 'lodash/get';
import { DashboardUtilsBag, MaterialType, MetalMaterial } from '../../types';

export const describeFactory = (
  features: string[],
  localize = (term: string) => term
) => (value: any) =>
  compact(features.map((key) => get(value, key)))
    .map(localize)
    .join(' ');

export const getMetalByConfig = (
  { type, quality }: { type: string; quality: string },
  { materials }: DashboardUtilsBag
): MetalMaterial => {
  const material = find(materials, {
    material: MaterialType.Metal,
    type,
    quality,
  }) as MetalMaterial;
  if (!material) {
    throw new Error(`material not found for ${quality} ${type}`);
  }
  return material;
};
