import * as React from 'react';

export interface DescribePriceOptions<CountryCodeType, CurrencyCodeType> {
  currencyCode: CurrencyCodeType;
  countryCode: CountryCodeType;
  removeDecimals?: boolean;
  convert?: boolean;
}

export interface CountryContext<CountryCodeType, CurrencyCodeType> {
  setCountry: (country: CountryCodeType) => void;
  setCurrency: (currency: CurrencyCodeType) => void;
  currencyCode: CurrencyCodeType;
  countryCode: CountryCodeType;
  formatPrice: (
    price: number,
    options: DescribePriceOptions<CountryCodeType, CurrencyCodeType>
  ) => string;
}

export const CountryContext = React.createContext<CountryContext<any, any>>({
  setCountry: (country) => {},
  setCurrency: (currency) => {},
  formatPrice: (price: number, options: any) => {
    if (options) {
      return price.toFixed(2);
    }
    return price.toString();
  },
  currencyCode: 'USD',
  countryCode: 'US',
});

interface CountryContextProviderProps<CountryCodeType, CurrencyCodeType> {
  children: React.ReactNode;
  defaults: {
    countryCode: CountryCodeType;
    currencyCode: CurrencyCodeType;
  };
  getCountry: () => Promise<CountryCodeType>;
  describePrice: (
    value: number,
    {
      currencyCode,
      countryCode,
      removeDecimals,
      convert,
    }: DescribePriceOptions<CountryCodeType, CurrencyCodeType>
  ) => string;
  getCurrencyCodeForCountry: (country: CountryCodeType) => CurrencyCodeType;
}

export function CountryContextProvider<
  CountryCodeType extends any,
  CurrencyCodeType extends any
>({
  getCountry,
  children,
  defaults,
  describePrice,
  getCurrencyCodeForCountry,
}: CountryContextProviderProps<
  CountryCodeType,
  CurrencyCodeType
>): JSX.Element {
  const [value, setValue] = React.useState(defaults);

  const updateCountry = async (): Promise<void> => {
    const newCountry = await getCountry();
    setValue({
      countryCode: newCountry,
      currencyCode: getCurrencyCodeForCountry(newCountry),
    });
  };

  React.useEffect(() => {
    updateCountry();
  }, []);

  const setCountry = (newCountry: CountryCodeType): void =>
    setValue({ ...value, countryCode: newCountry });

  const setCurrency = (newCurrency: CurrencyCodeType): void =>
    setValue({ ...value, currencyCode: newCurrency });

  const formatPrice = (
    price: number,
    options?: DescribePriceOptions<
      typeof defaults['countryCode'],
      typeof defaults['currencyCode']
    >
  ): string =>
    describePrice(price, {
      currencyCode: value.currencyCode,
      countryCode: value.countryCode,
      ...options,
    });

  return (
    <CountryContext.Provider
      value={{ ...value, setCountry, setCurrency, formatPrice }}
    >
      {children}
    </CountryContext.Provider>
  );
}
