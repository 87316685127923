import * as React from 'react';
import styled from 'styled-components';

const StyledCustomizerFieldTemplate = styled.div`
  display: flex;
  align-items: center;

  .tiary-customizer__field-template {
    &__section--main {
      flex: 1;
    }
  }
`;

export interface CustomizerFieldTemplateProps {
  className?: string;
  label?: string;
  error?: string;
  beforeContents?: React.ReactNode;
  afterContents?: React.ReactNode;
}

export const CustomizerFieldTemplate: React.FunctionComponent<CustomizerFieldTemplateProps> = ({
  className = '',
  label,
  error,
  beforeContents,
  afterContents,
  children,
}) => (
  <StyledCustomizerFieldTemplate
    className={`tiary-customizer__field-template ${className}`}
  >
    {beforeContents && (
      <div className="tiary-customizer__field-template__section--before">
        {beforeContents}
      </div>
    )}
    <div className="tiary-customizer__field-template__section--main">
      {label && (
        <div className="tiary-customizer__field-template__label">{label}</div>
      )}
      {children}
      {error && (
        <div className="tiary-customizer__field-template__error">{error}</div>
      )}
    </div>
    {afterContents && (
      <div className="tiary-customizer__field-template__section--after">
        {afterContents}
      </div>
    )}
  </StyledCustomizerFieldTemplate>
);
