import * as React from 'react';

import { CustomizerFieldProps } from './index';

import {
  CustomizerFieldExpandTemplate,
  CustomizerFieldExpandTemplateProps,
} from './ExpandTemplate';

type CustomizerFieldExpandTemplateNoExpandProps = Omit<
  CustomizerFieldExpandTemplateProps,
  'expand' | 'toggleExpand'
>;

interface MaterialExpandCustomizerFieldProps<ProductOptionType = any>
  extends CustomizerFieldProps<any, ProductOptionType>,
    CustomizerFieldExpandTemplateNoExpandProps {
  describeMaterial: (value: ProductOptionType) => string;
  expandedOnMount?: boolean;
}

export const MaterialExpandCustomizerField: React.FunctionComponent<MaterialExpandCustomizerFieldProps> = ({
  className,
  option,
  values,
  error,
  label,
  beforeContents,
  describeMaterial,
  expandedOnMount = false,
  children,
}) => {
  const [expand, setExpand] = React.useState(expandedOnMount);

  return (
    <CustomizerFieldExpandTemplate
      className={className}
      label={label || option.label}
      error={error}
      beforeContents={beforeContents}
      description={describeMaterial(values[option.name])}
      toggleExpand={() => setExpand(!expand)}
      expand={expand}
    >
      {children}
    </CustomizerFieldExpandTemplate>
  );
};
