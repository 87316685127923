import find from 'lodash/find';
import {
  ProductConfiguration,
  ProductOption,
  ProductOptionValue,
  ProductSpec,
} from '../types';

const DEFAULT_METAL = {
  quality: 'KARAT_14',
  type: 'GOLD_YELLOW',
};

const DEFAULT_STONE = {
  quality: 'GENUINE',
  type: 'DIAMOND_WHITE',
};

const DEFAULT_QUANTITY = { type: '2' };

const getDefaultTypeQualityPair = ({
  option,
  defaultPair,
}: {
  option: ProductOption;
  defaultPair: any;
}) => {
  const { values } = option;
  // check if the default pair definition actually exists
  const defaultExists = !!find(values, defaultPair);
  if (defaultExists) {
    // if it does, set the configuration
    return { [option.name]: defaultPair };
  }
  // if it doesn't, find the first zero-cost configuration
  // there will always be one because of how pricing is normalized
  return { [option.name]: values.find((v: ProductOptionValue) => !v.price) };
};

const getShortestChainLength = (chainLengthValues: ProductOptionValue[]) => {
  const values = chainLengthValues.map(({ type }) => Number(type));
  const sortedValues = values.sort((a, b) => (a > b ? 1 : -1));
  return `${sortedValues[0]}`;
};

export const getDefaultConfiguration = ({ options }: ProductSpec) => {
  let configuration: ProductConfiguration = {};
  options.forEach((option) => {
    const { name, type, values } = option;
    let defaultPair;

    if (type === 'METAL') {
      defaultPair = DEFAULT_METAL;
    } else if (type === 'STONE') {
      // Checks if the default stone exists (dimaond white simulated), if does we use it
      // If it doesn't exist, then try to use the first simulated option
      // And if that doesn't exist, just use the first value
      const defaultStoneExists = find(values, DEFAULT_STONE);
      if (!defaultStoneExists) {
        const firstSimulatedOption = find(values, { quality: 'SIMULATED' });
        if (firstSimulatedOption) {
          defaultPair = firstSimulatedOption;
        } else {
          defaultPair = values[0];
        }
      } else {
        defaultPair = DEFAULT_STONE;
      }
    } else if (type === 'CHAIN_LENGTH') {
      const shortestLength = getShortestChainLength(values);
      defaultPair = { type: shortestLength, quality: DEFAULT_METAL.quality };
    }

    if (type === 'METAL' || type === 'STONE' || type === 'CHAIN_LENGTH') {
      configuration = {
        ...configuration,
        ...getDefaultTypeQualityPair({ option, defaultPair }),
      };
    }

    if (type === 'QUANTITY') {
      configuration = {
        ...configuration,
        [name]: DEFAULT_QUANTITY,
      };
    }

    if (type === 'TEXT' || type === 'ENGRAVING') {
      configuration = {
        ...configuration,
        [name]: '',
      };
    }

    //just return the first entry in carat weights
    if (type == 'CARAT_WEIGHT' && values.length > 0){
      configuration={
        ...configuration,
        [name]:values[0]
      }
    }

    if (type == 'RING_SIZE' && values.length > 0) {
      configuration = {
        ...configuration,
        [name]: values[0]
      }
    }
  });
  return configuration;
};
