import find from "lodash/find"
import { ProductOption, ProductConfiguration } from "../"

export interface CleanConfigurationArgs {
  options: ProductOption[]
  configuration: ProductConfiguration
}

export const sanitizeConfiguration = ({
  options,
  configuration,
}: CleanConfigurationArgs) => {
  // we need to remove any irrelevant options from the configuration that
  // may have been applied because of a previous customization or the
  // application of a theme.
  // For example, if there's a theme with a setting for "STONE_1" and the
  // product does not have an option called "STONE_1" we should remove it so
  // that it does not appear in cart summaries or order transmissions
  // to the manufacturer.

  const cleanConfig = { ...configuration }
  Object.keys(cleanConfig).forEach(optionName => {
    if (optionName.includes("STONE") || optionName.includes("METAL")) {
      const value = configuration[optionName]
      let optionMatch, valueMatch
      optionMatch = find(options, { name: optionName })
      if (optionMatch && optionMatch.values) {
        valueMatch = find(optionMatch.values, value)
      }
      if (!optionMatch) {
        delete cleanConfig[optionName]
      }
      if (optionMatch && !valueMatch) {
        throw new Error("discovered a value that did not match")
      }
    }
  })

  return cleanConfig
}
