export const describeQuantity = ({ type }: { type: string }) => {
  const quantityLabels: { [type: string]: string } = {
    "0": "None",
    "1": "Single",
    "2": "Pair",
    "3": "Three",
    "4": "Four",
  }
  return quantityLabels[type] || "Single"
}
