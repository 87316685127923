import { getOptionLineItems } from '../getOptionLineItems';
import { describeFactory, getMetalByConfig } from '../../utils';
import { OptionCostCalculator } from '../../types';
import { findingsCostPlugin } from '../../plugins/findings';
import { rhodiumCostPlugin } from '../../plugins/rhodium';
import { finishingsCostPlugin } from '../../plugins/finishings';
import { metalSettingsCostPlugin } from '../../plugins/metalSettings';
import { nameCostPlugin } from '../../plugins/name';
import { MaterialType } from '../../../../types';

export const getCostForMetalOption: OptionCostCalculator = (
  { option, product, manufacturer, configuration },
  bag
) => {
  const { materials, localize = (a: any) => a } = bag;
  let lineItems = getOptionLineItems({
    option,
    manufacturer,
    configuration,
    materials,
    describe: describeFactory(['quality', 'color', 'type'], localize),
    quantify: ({ configuration, optionName, spec }) => {
      const material = getMetalByConfig(configuration[optionName], bag);
      return spec.weights[material.metal][material.quality];
    },
    predicate: ({ type, quality }) => ({
      material: MaterialType.Metal,
      type,
      quality,
    }),
  });

  const plugins = [nameCostPlugin, finishingsCostPlugin, rhodiumCostPlugin];

  // We only apply the findings & other plugins to the "Base Metal"
  if (option.name === 'METAL_1') {
    plugins.push(findingsCostPlugin);
    plugins.push(metalSettingsCostPlugin);
  }

  plugins.forEach((applyPlugin) => {
    lineItems = applyPlugin(
      {
        product,
        lineItems,
        manufacturer,
        configuration,
      },
      bag
    );
  });

  return lineItems;
};
