import {
  RendererFixedLayout,
  RendererLayout,
  RendererLayoutMode,
} from '../../../types/renderer';
import getConstraintsRect from './getConstraintsRect';
import { getImgixUrl } from './getImgixUrl';

interface GetUrlForLayerArgs {
  path: string;
  layout: RendererLayout;
  renderConstraints?: {
    [key: string]: any;
  };
  backgroundHex?: string;
  baseUrl?: string;
  buildImageUrl?: typeof getImgixUrl;
}

const getUrlForLayer = ({
  path,
  baseUrl,
  layout,
  backgroundHex,
  renderConstraints = {
    cursorX: 0.5,
    cursorY: 0.5,
    zoom: 1,
  },
  buildImageUrl = getImgixUrl,
}: GetUrlForLayerArgs): string => {
  const fixedSizeModes = [
    RendererLayoutMode.Fixed,
    RendererLayoutMode.Responsive,
  ];

  let pixelRatio = 1;
  if (typeof window !== 'undefined' && window.devicePixelRatio) {
    pixelRatio = window.devicePixelRatio;
  }

  // Background Color
  // We bake this into the image when possible because it reduces filesize.
  const backgroundColor = backgroundHex ? backgroundHex.replace('#', '') : null;

  if (fixedSizeModes.includes(layout.mode)) {
    const {
      width,
      height,
      zoomFactor = 1,
      focalPointX = 0.5,
      focalPointY = 0.5,
    } = layout as RendererFixedLayout;
    const { zoom = 1, cursorX = 1000, cursorY = 1000 } = renderConstraints;

    const sourceRectangleRegion = getConstraintsRect({
      aspectRatio: width / height,
      zoom: zoom * zoomFactor,
      focalPoint: { x: cursorX, y: cursorY },
      focus: { x: focalPointX, y: focalPointY },
    });

    const url = buildImageUrl(`/renders/${path}`, {
      baseUrl,
      height,
      width,
      pixelRatio,
      ...(sourceRectangleRegion && { sourceRectangleRegion }),
      ...(backgroundColor && { backgroundColor }),
    });

    return url;
  } else if (layout.mode === RendererLayoutMode.Contain) {
    const { width, height } = layout;

    const url = buildImageUrl(`/renders/${path}`, {
      baseUrl,
      height,
      width,
      pixelRatio,
      ...(backgroundColor && { backgroundColor }),
    });

    return url;
  } else if (layout.mode === RendererLayoutMode.Native) {
    const { scale } = layout;

    const url = buildImageUrl(`/renders/${path}`, {
      height: scale,
      width: scale,
      baseUrl,
      pixelRatio,
      ...(backgroundColor && { backgroundColor }),
    });

    return url;
  }

  throw new Error('url could not be constructed');
};

export { getUrlForLayer };
