// writing a function that takes in the product and the sales data
// provides the discounted price
import * as _ from 'lodash';

// import { Timestamp } from "@firebase/firestore-types";

import { getDefaultConfiguration } from "./getDefaultConfiguration";
import { getPriceForConfiguration } from "./getPriceForConfiguration";


interface getBargainPriceProps {
  product: any,
  salesData: any,
  configuration?: any
}

export const getBargainPrice = (
  {
    product, 
    salesData, 
    configuration
  }: getBargainPriceProps) => {
  // console.log('start')
  //get default price assuming there's no sale 
  const defaultPrice = getPriceForConfiguration({
    product: product,
    configuration: configuration ?? getDefaultConfiguration(product)
  })
  
  const MAX_DISCOUNT = 0.3

  //*allow max of 30% discount on ongoing sales

  const allowableMinPrice = defaultPrice * (1.0 - MAX_DISCOUNT)

  

  //if no sales data, return default price
  if (!salesData) {
    console.log('no sales found')
    return defaultPrice
  }

  // lets walk through all the sales
  const potentialPrices: number[] = []
  const currentDate = new Date();

  //check if there are any ongoing promotions
  
  const currentPromotions = _.filter(salesData, sale => {
    // console.log(sale.startDate,sale.endDate,'these are the dates it gets from firebase')
    const startDate = new Date(sale.startDate);
    const endDate = new Date(sale.endDate);
    // console.log(startDate,endDate,'these are the dates');
    return _.inRange(currentDate.getTime(), startDate.getTime(), endDate.getTime() + 86400000); // +1 day in milliseconds
  });

  //?what if we have no sales here?

  currentPromotions.forEach((promotion) => {
    // console.log(promotion, 'my sale')
    // console.log(promotion.startDate, 'start')
    // console.log(promotion.endDate, 'endDate')
    
    // first check if sale is valid. if valid, check if specificProducts is in the array. if yes isolate price
    const productMatch = _.find(promotion.specificProducts, { sku: product.sku })

    if (productMatch) {
      // this promotion is valid for this product so apply the discount
      const discount = productMatch.percentage ?
        productMatch.percentage * defaultPrice :
        promotion.defaultPercent * defaultPrice

      const potentialBargainPrice = defaultPrice - discount
      //only push if the bargain price thats generated is larger than the allowed minimum (the lower limit)
      //aka only push if the discount is less than the max discount
      // same as below: if (productMatch.percentage < 60) {
      //  code here
      // }

      potentialPrices.push(Math.max(potentialBargainPrice, allowableMinPrice))
    }
  })

  const bestDealPrice = _.min(potentialPrices) ?? defaultPrice
  // console.log(potentialPrices,'This should have potential Prices')
  // if the best deal price is lower than the allowable min price, use the allowable min price. 
  return Math.max(allowableMinPrice, bestDealPrice);
  

  // sales data looks like
  // {
  //   "nodes": [
  //     {
  //       "id": "charms",
  //       "specificProducts": [
  //         {
  //           "percentage": 20,
  //           "sku": "ADNS-0029Y",
  //           "stack": false
  //         },
  //         {
  //           "percentage": 15,
  //           "sku": "ADNS-0029SY",
  //           "stack": false
  //         }
  //       ],
  //       "endDate": "2024-02-06",
  //       "startDate": "2024-02-22",
  //       "defaultPercent": 20
  //     },
  //     {
  //       "id": "storewide",
  //       "specificProducts": [
  //         {
  //           "percentage": 20,
  //           "sku": "ADNS-0029Y",
  //           "stack": true
  //         },
  //         {
  //           "percentage": 15,
  //           "sku": "ADNS-0029SY",
  //           "stack": true
  //         }
  //       ],
  //       "endDate": "2024-02-28",
  //       "startDate": "2024-02-27",
  //       "defaultPercent": 10
  //     }
  //   ]
  // }


}