import { ProductCostPlugin, ProductCostLineItem } from '../../types';

// this plugin is called when an item has no "options" & a custom price finding
export const customPricePlugin: ProductCostPlugin = ({
  product,
  lineItems,
}) => {
  const newLineItems: ProductCostLineItem[] = [];

  const productHasNoOptions = product.options.length === 0;

  const productFindings = product.findings || [];
  const productCustomFinding = productFindings.find(
    (finding) => finding.type === 'CUSTOM_PRICE'
  );

  if (productHasNoOptions && productCustomFinding) {
    newLineItems.push({
      label: `Custom Price`,
      type: 'CUSTOM_PRICE',
      description: `A customer price set by you.`,
      // @ts-ignore
      cost: productCustomFinding.customPrice * productCustomFinding.quantity,
      quantity: productCustomFinding.quantity,
      // @ts-ignore
      unitCost: productCustomFinding.customPrice,
    });

    return [...lineItems, ...newLineItems];
  }

  return lineItems;
};
