import React from 'react'
import styled from 'styled-components'

import { BaseSize, Color, getPx, TextSize } from '../style'
import iconSelect from '../assets/icons/icon-select-png'

export const StyledPlainFieldSelect = styled.div`
  select {
    cursor: pointer;
    appearance: none;
    box-sizing: content-box;
    line-height: ${getPx(BaseSize.Baseline)};
    padding: ${getPx(BaseSize.Grid, 2 / 3)} ${getPx(BaseSize.Grid, 3 / 2)}
      ${getPx(BaseSize.Grid, 2 / 3)} ${getPx(BaseSize.Grid)};
    color: ${Color.OffBlack};
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    outline: none;
    font-size: ${getPx(TextSize.Normal)};
    width: calc(100% - ${getPx(BaseSize.Grid, 5 / 2)});
    margin-top: 4px;
  }

  .plain-form__select {
    &__wrapper {
      position: relative;
    }

    &__arrow {
      display: block;
      background-image: url(${iconSelect});
      position: absolute;
      top: 50%;
      right: 0;
      height: 16px;
      width: 16px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: -8px;
      margin-right: 9px;
      pointer-events: none;
    }
  }
`

interface PlainFieldSelectProps {
  name?: string
  title?: string
  placeholder?: string
  required?: boolean
  autoComplete?: string
  options: Array<{ label: string; value?: string }>
  value?: string
  onValueChange: (nextValue: string) => void
  className?: string
}

export const PlainFieldSelect = ({
  name,
  placeholder,
  required,
  autoComplete,
  options,
  value,
  onValueChange,
  className,
}: PlainFieldSelectProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    onValueChange(e.target.value)
  return (
    <StyledPlainFieldSelect className={`form__field-group__item ${className}`}>
      <div className="plain-form__select__wrapper">
        <div className="plain-form__select__arrow" />
        <select
          name={name}
          required={required}
          autoComplete={autoComplete}
          value={value}
          onChange={handleChange}
        >
          {!!placeholder && <option>{placeholder}</option>}
          {options.map(o => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </div>
    </StyledPlainFieldSelect>
  )
}
