
import * as _ from 'lodash';

export const isGoldmanProduct = (sku: string) => {
        const pattern = /^FGM-/
        return pattern.test(sku)
}

export const generateGoldmanReferenceNumber=({product,configuration}:{product:any,configuration:any})=>{
        //this generates a style number from Goldman corresponding to the actual style number
        const {styleFamily,styleSetCode} = product


        //will need the metal data from the product
        const metalOptions = _.find(product.options,{name:"METAL_1"}).values
        const caratOption = _.find(product.options,{name:"CARAT_WEIGHT"})

        //ring size is null for pendants and studs
        const ringSizeOptions = _.find(product.options,{name:"RING_SIZE"})?.values || null
        
        //configuration might be incomplete
        if((caratOption && !configuration.CARAT_WEIGHT)
           || (ringSizeOptions && !configuration.RING_SIZE))
        {
                return "incomplete configuration"
        }

        //get the notations
        const {metalNotation,metalSuffix} = _.find(metalOptions,{
                type:configuration.METAL_1.type,
                quality:configuration.METAL_1.quality})

        
        //get it from carat weight if it has that options or get it from ring size

        
        const centerStoneNotation = caratOption ?
                                        _.find(caratOption.values,{type:configuration.CARAT_WEIGHT.type}).centerStoneNotation
                                        : ringSizeOptions ?
                                                _.find(ringSizeOptions,{type:configuration.RING_SIZE.type}).centerStoneNotation 
                                                :""

                                    

        const ringSizeNotation = "";
     
        const finalReferenceNumber = `${styleFamily}${centerStoneNotation}${metalNotation}${ringSizeNotation}-${styleSetCode}${metalSuffix}`
        
        return finalReferenceNumber
    }

export const calculateGoldmanCost=({product,configuration,goldmanData}:{product:any,configuration:any,goldmanData:any})=>{
        

        const referenceNumber = generateGoldmanReferenceNumber({
                product:product,
                configuration:configuration
        })
        
        if(referenceNumber){
                //here's where I would write the logic for looking up in the database
                const correctEntry = goldmanData[0].costs[referenceNumber];
                console.log(referenceNumber,correctEntry,'look this one up in the database')
                
                return correctEntry;
                
        }

        return undefined;

}