import { ShippingOptionCode } from "../types"

import { shippingOptions } from "../config"

export const describeShippingOption = (
  shippingOptionCode: ShippingOptionCode,
) => {
  if (!shippingOptionCode) {
    return ""
  }
  const option = shippingOptions.find(o => o.code === shippingOptionCode)
  if (!option) {
    return ""
  }
  const { description, provider } = option
  return `${description} (${provider})`
}
