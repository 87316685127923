import * as React from 'react';
import styled from 'styled-components';

import { BaseSize, getPx } from '../../style/index';
import { PlainFieldSelect } from '../../shared/PlainFieldSelect';
import { groupBy } from '../../utils/lodash';

const StyledTiaryQualityOptions = styled.div`
  margin-top: ${getPx(BaseSize.Baseline, 1 / 2)};
  margin-bottom: ${getPx(BaseSize.Baseline, 1 / 2)};
  display: flex;
`;

interface TiaryQualityOptionsProps<ProductOptionType = any> {
  className?: string;
  options: ProductOptionType[];
  value: ProductOptionType;
  onChange: (value: any) => void;
  describeQuality: (quality: any) => string;
  hideQualityOptions?: string[];
}

export const TiaryQualityOptions: React.FunctionComponent<TiaryQualityOptionsProps> = ({
  className,
  value,
  onChange,
  describeQuality,
  options,
  hideQualityOptions = [],
  ...rest
}) => {
  if (!value) {
    return null;
  }
  const optionsByType = groupBy(options, 'type');
  const filteredOptionsByType = optionsByType[value.type].filter(
    ({ quality }: any) => quality && !hideQualityOptions.includes(quality)
  );

  const qualityOptions = filteredOptionsByType.map((o: any) => ({
    label: describeQuality(o.quality) || '',
    value: o.quality || '',
  }));

  const handleChange = (nextQuality: string): void => {
    const optionValue = optionsByType[value.type].find(
      ({ quality }: any) => nextQuality === quality
    );
    if (optionValue) {
      onChange({
        type: optionValue.type,
        quality: optionValue.quality,
      });
    }
  };

  return qualityOptions.length > 0 ? (
    <StyledTiaryQualityOptions className={className} {...rest}>
      <PlainFieldSelect
        className="quality-options__select"
        options={qualityOptions}
        value={value.quality || ''}
        onValueChange={handleChange}
        required
      />
    </StyledTiaryQualityOptions>
  ) : null;
};
