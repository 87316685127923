import { Product, ProductOption } from '../types';

import { mutatedTableValue, tableRowData, tableValuePrice } from './table';

const fields: any[] = [
  { label: 'SKU', value: 'sku' },
  { label: 'Name', value: 'name' },
  { label: 'Type', value: 'type' },
  { label: 'Category', value: 'category' },
  { label: 'Collection', value: 'collection' },
  {
    label: 'Description',
    value: mutatedTableValue('description', {
      mutate: (description: string) => description,
      defaultValue: 'No description available.',
    }),
  },
  {
    label: 'Carat Weight',
    value: mutatedTableValue('caratWeight', {
      mutate: (caratWeight: string) => caratWeight,
      defaultValue: 'Missing',
    }),
  },
  {
    label: 'Product Size',
    value: mutatedTableValue('productSize', {
      mutate: (productSize: string) => productSize,
      defaultValue: 'Missing',
    }),
  },
  {
    label: 'Link',
    value: mutatedTableValue('slug', {
      mutate: (slug: string) => `https://tiary.com/products/${slug}`,
      defaultValue: 'none',
    }),
  },
  {
    label: 'image_link',
    value: mutatedTableValue('sku', {
      mutate: (sku: string) =>
        `https://tiary.imgix.net/marketing-renders/${sku}.jpg?w=500&h=500&fit=crop`,
      defaultValue: 'Missing',
    }),
  },
  { label: 'Base Price', value: tableValuePrice('basePrice') },
  {
    label: 'Customizer Options',
    value: mutatedTableValue('options', {
      mutate: (productOptions: ProductOption[]) => {
        const allCustomizerOptions = productOptions.map(
          (productOption) => productOption.label
        );

        return allCustomizerOptions.join(', ');
      },
      defaultValue: '',
    }),
  },
];
/*
// For exporting the product catalog for Facebook marketing.
const facebookCatalogFields: any[] = [
  { label: 'id', value: 'sku' },
  { label: 'title', value: 'name' },
  {
    label: 'description',
    value: mutatedTableValue('description', {
      mutate: (description: string) => description,
      defaultValue: 'No description available.',
    }),
  },
  {
    label: 'availability',
    value: mutatedTableValue('', {
      mutate: () => 'available for order',
      defaultValue: 'none',
    }),
  },
  {
    label: 'condition',
    value: mutatedTableValue('', {
      mutate: () => 'new',
      defaultValue: 'none',
    }),
  },
  {
    label: 'price',
    value: mutatedTableValue('', {
      mutate: (product: Product) => {
        // console.log('exporting', product);
        const defaultConfig = getDefaultConfiguration(product);
        const price = getPriceForConfiguration({
          product,
          configuration: defaultConfig,
        });
        return `${price} USD`;
      },
      defaultValue: '0.00 USD',
    }),
  },
  {
    label: 'link',
    value: mutatedTableValue('slug', {
      mutate: (slug: string) => `https://tiary.com/products/${slug}`,
      defaultValue: 'none',
    }),
  },
  {
    label: 'image_link',
    value: mutatedTableValue('sku', {
      mutate: (sku: string) =>
        `https://tiary.imgix.net/marketing-renders/${sku}.jpg?w=500&h=500&fit=crop`,
      defaultValue: 'none',
    }),
  },
  {
    label: 'brand',
    value: mutatedTableValue('', {
      mutate: () => 'Tiary',
      defaultValue: 'Tiary',
    }),
  },
];

// For exporting the product catalog for Facebook marketing.
const popularProductsFields: any[] = [
  { label: 'sku', value: 'sku' },
  { label: 'name', value: 'name' },
  { label: '# sold', value: 'amount' },
  {
    label: 'product page',
    value: mutatedTableValue('slug', {
      mutate: (slug: string) => `https://tiary.com/products/${slug}`,
      defaultValue: 'none',
    }),
  },
];
*/

export const productColumnLabels = fields.map(({ label }) => label);

export const tableDataFromProducts = (products: Product[]) =>
  products.map((product) => tableRowData(product, fields));

export const csvFromFacebookProducts = () => {
  console.warn('csvFromFacebookProducts has been removed from tiary-shared');
};

export const csvFromProducts = () => {
  console.warn('csvFromProducts has been removed from tiary-shared');
};

// you can see the type for popularProducts in the dashboard export button component
export const csvFromPopularProducts = () => {
  console.warn('csvFromPopularProducts has been removed from tiary-shared');
};
