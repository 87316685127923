import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import {
  ProductOptionValue,
  MaterialType,
  FindingMaterial,
  ProductOptionType,
  DashboardProductChainOption,
  DashboardProductFindingAdditionalChain,
} from '../../../../types';

import { ProductCostPlugin, ErrorLineItem } from '../../types';
import { describeFactory, getMetalByConfig } from '../../utils';
import { getCostForChainOption } from '../../options/chain';

const FINDINGS_WITHOUT_ASSEMBLY_COSTS = ['ADDITIONAL_CHAIN', 'BRACELET'];

export const findingsCostPlugin: ProductCostPlugin = (
  { product, lineItems, configuration, manufacturer },
  bag
) => {
  const { materials, localize } = bag;
  const { findings = [] } = product;

  const baseMetalConfig = configuration['METAL_1'] as ProductOptionValue;
  const { quality = '' } = baseMetalConfig;

  const describe = describeFactory(['quality', 'metal', 'finding'], localize);
  const metalMaterial = getMetalByConfig(
    { type: baseMetalConfig.type, quality },
    bag
  );

  const findingItems = findings.map(({ type: finding, quantity, ...rest }) => {
    try {
      // If the finding is an additional chain
      if (finding === 'ADDITIONAL_CHAIN' && quantity > 0) {
        const { reducedRate } = rest as DashboardProductFindingAdditionalChain;
        // Get the chain option from the product
        const productChainOption = product.options.find(
          (productOption) => productOption.type === ProductOptionType.Chain
        );

        // Get the chain configuration if the product has a chain option
        const chainConfiguration = productChainOption
          ? configuration[productChainOption.name]
          : undefined;

        // if the chain config exists
        if (chainConfiguration) {
          const features = {
            material: MaterialType.Finding,
            finding,
            metal: metalMaterial.metal,
            quality,
          };

          // Get the costs of the chain
          const chainCosts = getCostForChainOption(
            {
              option: productChainOption as DashboardProductChainOption,
              product,
              manufacturer,
              configuration,
            },
            bag
          );

          // Get the base unit cost
          let unitCost = sumBy(chainCosts, 'cost');

          // If we have a reduced rate from 1-100
          if (reducedRate > 0 && reducedRate <= 100) {
            // The percentage we're taking off
            const reducedPercentage = reducedRate / 100;
            // The unit cost is now the base unit cost - reducedPercentage
            unitCost = unitCost * reducedPercentage;
          }

          return {
            label: 'Finding',
            description: `${describe(features)} (-${reducedRate}%)`,
            type: MaterialType.Finding,
            cost: unitCost * quantity,
            unitCost,
            quantity,
          };
        } else {
          return {
            type: 'ERROR',
            label: 'Error',
            description:
              'Found Additional Chain finding but theres no chain configuration.',
          } as ErrorLineItem;
        }
      } else {
        // GET THE MATERIAL ID
        // find the material based on the predicate
        const features = {
          material: MaterialType.Finding,
          finding,
          metal: metalMaterial.metal,
          quality,
        };
        const material = find(materials, features) as FindingMaterial;
        if (!material || !material.id) {
          throw new Error(`material could not be found ${describe(features)}`);
        }

        // GET THE MANUFACTURER COST
        // find the cost from the manufacturer
        const unitCost = manufacturer.costs.materials[material.id];
        if (typeof unitCost !== 'number') {
          throw new Error(
            `cost not found for ${describe(features)} from ${manufacturer.name}`
          );
        }

        return {
          label: 'Finding',
          description: describe(features),
          type: MaterialType.Finding,
          cost: unitCost * quantity,
          unitCost: unitCost,
          features: material,
          quantity,
        };
      }
    } catch (error) {
      return {
        type: 'ERROR',
        label: 'Error',
        description: error.message as string,
      } as ErrorLineItem;
    }
  });

  // some findings do not impact assembly costs.
  const assembledFindings = findings.filter(
    ({ type }) => !FINDINGS_WITHOUT_ASSEMBLY_COSTS.includes(type)
  );

  const assemblySteps = sumBy(assembledFindings, 'quantity');
  const assemblyCost = manufacturer?.costs?.assembly;
  const assemblyLineItem =
    assemblySteps > 0
      ? [
          {
            label: 'Assembly',
            description: 'Assembly Steps',
            type: 'ASSEMBLY',
            cost: assemblyCost * assemblySteps,
            unitCost: assemblyCost,
            quantity: assemblySteps,
          },
        ]
      : [];

  return [...lineItems, ...findingItems, ...assemblyLineItem];
};
