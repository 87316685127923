export const getPx = (base: number, units: number = 1, offset: number = 0) =>
  `${base * units - offset}px`

export const ButtonResetStyle = `
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  text-align: inherit;
  outline: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`
