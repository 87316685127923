import { CountryCode } from "./countryCode"

export enum ShippingOptionCode {
  FEDEX_FREE = "FEDEX_FREE",
  FEDEX_EXPIDITED = "FEDEX_EXPIDITED",
  UPU_FREE = "UPU_FREE",
  UPU_EXPIDITED = "UPU_EXPIDITED",
  PUROLATOR_FREE = "PUROLATOR_FREE",
  PUROLATOR_EXPIDITED = "PUROLATOR_EXPIDITED",
  FEDEX_INTERNATIONAL = "FEDEX_INTERNATIONAL",
  FEDEX_CANADA_FREE = "FEDEX_CANADA_FREE",
  FEDEX_CANADA_EXPIDITED = "FEDEX_CANADA_EXPIDITED",
}

export interface ShippingOption {
  code: ShippingOptionCode
  price: number
  description: string
  provider: string
  deliveryTime: string
  reason: string
  country: CountryCode
  hidden?: boolean
  active?: boolean
}
