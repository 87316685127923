export enum RendererLayoutMode {
  Fixed = 'FIXED',
  Responsive = 'RESPONSIVE',
  Contain = 'CONTAIN',
  Native = 'NATIVE',
}

export interface RendererFixedLayout {
  mode: RendererLayoutMode.Fixed;
  height: number;
  width: number;
  focalPointX?: number;
  focalPointY?: number;
  zoomFactor?: number;
}
export interface RendererResponsiveLayout {
  mode: RendererLayoutMode.Responsive;
  height: number;
  width: number;
  focalPointX?: number;
  focalPointY?: number;
  zoomFactor?: number;
}
export interface RendererNativeLayout {
  mode: RendererLayoutMode.Native;
  scale?: number;
}
export interface RendererContainLayout {
  mode: RendererLayoutMode.Contain;
  height: number;
  width: number;
}
export type RendererLayout =
  | RendererFixedLayout
  | RendererNativeLayout
  | RendererResponsiveLayout
  | RendererContainLayout;
