import * as React from 'react';

import { StyledTiaryRenderer } from './display';
import Layer from './Layer';
import Preloader from './Preloader';

import { GlobalContext } from '../../contexts';
import {
  RendererFixedLayout,
  RendererLayout,
  RendererLayoutMode,
} from '../../types/renderer';

const imageLayers = ['METAL_1', 'STONE_1', 'STONE_2', 'STONE_3'];

const TRANSITION_DURATION = 500;

const defaultGetOrderedRenderOptions = (
  options: { type: string }[]
): { type: string }[] =>
  options
    .filter((o) => o.type === 'STONE' || o.type === 'METAL')
    .sort((a) => (a.type === 'STONE' ? 1 : -1));

export interface TiaryRendererProps<
  ProductSpecType = any,
  ProductConfigurationType = any,
  ProductOptionType = any
> {
  productSpec: ProductSpecType;
  configuration: ProductConfigurationType;
  getDefaultConfiguration?: (spec: ProductSpecType) => ProductConfigurationType;
  getOrderedRenderOptions?: (
    options: ProductOptionType[]
  ) => ProductOptionType[];
  preload?: boolean;
  loadIndicator?: boolean;
  backgroundHex?: string;
  layout: RendererLayout;
  className?: string;
  style?: any;
  renderRef?: React.Ref<any>;
}

const DEFAULT_LAYOUT: RendererFixedLayout = {
  mode: RendererLayoutMode.Fixed,
  height: 500,
  width: 500,
  focalPointX: 0.5,
  focalPointY: 0.8,
  zoomFactor: 1,
};

interface LoadedLayerList {
  [layerName: string]: boolean;
}

export const TiaryRenderer: React.FunctionComponent<TiaryRendererProps> = ({
  productSpec,
  configuration,
  preload,
  className,
  backgroundHex = '#ffffff',
  layout = DEFAULT_LAYOUT,
  getDefaultConfiguration,
  getOrderedRenderOptions = defaultGetOrderedRenderOptions,
  style,
  renderRef,
}) => {
  const { imgixBaseUrl } = React.useContext(GlobalContext);

  // loading state
  const [loadedLayers, setLoadedLayers] = React.useState<LoadedLayerList>({});
  const [initialLoad, setInitialLoad] = React.useState(false);

  // config state
  const [currConfig, setCurrConfig] = React.useState<typeof configuration>({});
  const [prevConfig, setPrevConfig] = React.useState<
    typeof configuration | null
  >(null);

  // animation state
  const [animProgress, setAnimProgress] = React.useState<number | null>(null);
  const animationFrameRequestId = React.useRef<number | null>(null);
  const animationStart = React.useRef<number | null>(null);

  // Manage Loading state
  React.useEffect(() => {
    const productOptions = productSpec.options || [];
    const nextLoadedLayers: LoadedLayerList = {};
    productOptions.forEach((o: typeof productSpec.options) => {
      const key = o.type;
      if (imageLayers.includes(key)) {
        nextLoadedLayers[key] = false;
      }
    });
    if (productSpec.shadow) {
      nextLoadedLayers.shadow = false;
    }
    setLoadedLayers(nextLoadedLayers);
  }, []);

  // Manage animations
  React.useEffect(() => {
    handleTransition();
    return () => {
      if (animationFrameRequestId.current) {
        window.cancelAnimationFrame(animationFrameRequestId.current);
      }
      clearTransition();
    };
  }, [currConfig]);

  React.useEffect(() => {
    const defaults = getDefaultConfiguration
      ? { ...getDefaultConfiguration(productSpec) }
      : {};
    const nextcurrConfig = { ...defaults, ...configuration };
    setPrevConfig(currConfig);
    setCurrConfig(nextcurrConfig);
  }, [configuration, productSpec]);

  const stepAnimation = (timestamp: number): void => {
    animationStart.current = animationStart.current || timestamp;
    const elapsed = timestamp - animationStart.current;
    if (elapsed > TRANSITION_DURATION) {
      return clearTransition();
    }
    setAnimProgress(elapsed / TRANSITION_DURATION);
    animationFrameRequestId.current = window.requestAnimationFrame(
      (nextTimestamp) => {
        stepAnimation(nextTimestamp);
      }
    );
  };

  const handleTransition = (): void => {
    setAnimProgress(0);
    animationFrameRequestId.current = window.requestAnimationFrame(
      (timestamp) => {
        stepAnimation(timestamp);
      }
    );
  };

  const clearTransition = (): void => {
    setAnimProgress(1);
    animationStart.current = null;
  };

  const handleLayerLoaded = (layerName: string): void => {
    const nextLoadedLayers = {
      ...loadedLayers,
      [layerName]: true,
    };
    setLoadedLayers(nextLoadedLayers);
    if (Object.values(nextLoadedLayers).indexOf(false) === -1) {
      setInitialLoad(true);
    }
  };

  const options = getOrderedRenderOptions(productSpec.options || []);

  const handleLayerLoadedFactory = (name: string) => () =>
    handleLayerLoaded(name);

  let placeholderPath = null;
  if (layout.mode === RendererLayoutMode.Native) {
    placeholderPath = options[0].values.filter((render: any) => !!render)[0]
      .render;
    if (!placeholderPath) {
      throw new Error(
        'tried to render a native layout renderer with no layers'
      );
    }
  }

  return (
    <StyledTiaryRenderer
      ref={renderRef}
      height={
        layout.mode === RendererLayoutMode.Fixed ||
        layout.mode === RendererLayoutMode.Responsive
          ? layout.height
          : undefined
      }
      width={
        layout.mode === RendererLayoutMode.Fixed ||
        layout.mode === RendererLayoutMode.Responsive
          ? layout.width
          : undefined
      }
      responsive={layout.mode === RendererLayoutMode.Responsive}
      className={className}
      style={style}
    >
      <div>
        {productSpec.shadow && (
          <Layer
            path={productSpec.shadow}
            layout={layout}
            onLoad={handleLayerLoadedFactory('shadow')}
            onError={handleLayerLoadedFactory('shadow')}
            backgroundHex={backgroundHex}
            renderConstraints={productSpec.renderConstraints}
            imgixBaseUrl={imgixBaseUrl}
          />
        )}
        {productSpec.base && (
          <Layer
            path={productSpec.base}
            layout={layout}
            onLoad={handleLayerLoadedFactory('base')}
            onError={handleLayerLoadedFactory('base')}
            backgroundHex={backgroundHex}
            renderConstraints={productSpec.renderConstraints}
            imgixBaseUrl={imgixBaseUrl}
          />
        )}
        {placeholderPath && (
          // If the layout mode is native then we use a hidden render to set
          // the size of the container to the native size of the image. All of
          // the other layers will be position absolute.
          <Layer
            path={placeholderPath}
            opacity={0}
            layout={layout}
            positionStatic={true}
            preload={false}
            renderConstraints={productSpec.renderConstraints}
            imgixBaseUrl={imgixBaseUrl}
          />
        )}
        {options.map(({ name, type, label, values }) => {
          const option = currConfig[name];
          if (!option) {
            return null;
          }

          let prevSelected = null;
          if (prevConfig && prevConfig[name] && prevConfig[name] !== option) {
            const prevOption = prevConfig[name];
            prevSelected =
              values.find(
                (o: any) =>
                  typeof prevOption !== 'string' &&
                  !!prevOption.type &&
                  o.type === prevOption.type
              ) || null;
          }

          const selected =
            values.find(
              (o: any) =>
                typeof option !== 'string' &&
                !!option.type &&
                o.type === option.type
            ) || null;

          const imageRendering = type === 'STONE' ? 'pixelated' : 'auto';

          return (
            <div key={`render-${name}-${label}`}>
              {prevSelected && prevSelected.render && (
                <Layer
                  path={prevSelected.render}
                  layout={layout}
                  imageRendering={imageRendering}
                  renderConstraints={productSpec.renderConstraints}
                  preload={false}
                  imgixBaseUrl={imgixBaseUrl}
                />
              )}
              {selected && selected.render && (
                <Layer
                  path={selected.render}
                  onLoad={handleLayerLoadedFactory(name)}
                  onError={handleLayerLoadedFactory(name)}
                  layout={layout}
                  opacity={prevSelected ? animProgress || 0 : 1}
                  imageRendering={imageRendering}
                  renderConstraints={productSpec.renderConstraints}
                  preload={false}
                  imgixBaseUrl={imgixBaseUrl}
                />
              )}
            </div>
          );
        })}
        {preload && initialLoad && (
          <Preloader productSpec={productSpec} layout={layout} />
        )}
      </div>
    </StyledTiaryRenderer>
  );
};
