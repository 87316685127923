import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import {
  ProductCostPlugin,
  ProductCostLineItem,
  ValueLineItem,
  ErrorLineItem,
} from '../../types';
import { describeFactory } from '../../utils';

export const rhodiumCostPlugin: ProductCostPlugin = (
  { manufacturer, lineItems },
  { localize }
) => {
  const { rhodium = {} } = manufacturer?.costs || {};
  const rhodiumItems: ProductCostLineItem[] = [];
  const describe = describeFactory(['quality', 'metal'], localize);

  lineItems.forEach((item) => {
    try {
      // if the item type is metal
      if (item.type === 'METAL') {
        const valueItem = item as ValueLineItem;

        // if the metal is gold
        if (valueItem.features?.metal === 'GOLD') {
          const { quantity: weight, features } = valueItem;
          const { quality } = features;

          const matchingCosts = rhodium[quality].filter(
            ({ minWeight }) => minWeight <= weight
          );

          const rhodiumMatch = last(sortBy(matchingCosts, 'minWeight'));

          if (!rhodiumMatch) {
            throw new Error(
              `No rhodium cost for ${weight}dwt ${describe(features)}`
            );
          }

          const { cost, minWeight } = rhodiumMatch;

          // if the item is white gold, actually give the rhodium price
          if (valueItem.features?.type === 'GOLD_WHITE') {
            rhodiumItems.push({
              label: 'Rhodium',
              type: 'RHODIUM',
              description: `Rhodium - > ${minWeight}dwt ${describe(features)}`,
              cost: cost,
              quantity: 1,
              unitCost: cost,
              features,
            });
          } else {
            // if the item is gold, but not white, add a "fake" rhodium charge so it's easier to follow when breaking down pricing
            // get the rhodium cost, divided by the amount of different metals in the karat
            const individualRhodiumCost = cost / 3;
            rhodiumItems.push({
              label: `Rhodium balance`,
              type: 'FAKE_RHODIUM',
              description: `Balancing the rhodium -> ${minWeight}dwt ${describe(
                features
              )}`,
              cost: individualRhodiumCost,
              quantity: 1,
              unitCost: individualRhodiumCost,
              features,
            });
          }
        } else {
          return;
        }
      }
    } catch (error) {
      rhodiumItems.push({
        type: 'ERROR',
        label: 'Error',
        description: error.message as string,
      } as ErrorLineItem);
    }
  });

  return [...lineItems, ...rhodiumItems];
};
