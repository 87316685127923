export enum FirestoreCollections {
  USERS = 'users',
  ADMINS = 'admins',
  CART = 'cart',
  DEPOSITS = 'deposits',
  DASHBOARD = 'dashboard',
  LEGACY_ORDERS = 'legacyOrders',
  ORDER_DRAFTS = 'order-drafts',
  ORDERS = 'orders',
  POINT_OF_SALES = 'point-of-sales',
  PRICE_RULES = 'price-rules',
  PRODUCT_METADATA = 'product-metadata',
  PRODUCTS = 'products',
  SHIPPING_OPTIONS = 'shipping-options',
  TOUCHPOINTS = 'touchpoints',
  PRODUCT_REVIEWS = 'product-reviews',
  INSIGHTS = 'insights',
  INVENTORY = 'inventory',
}
