import sortBy from 'lodash/sortBy';
import last from 'lodash/last';

import {
  ProductCostPlugin,
  ProductCostLineItem,
  ValueLineItem,
  ErrorLineItem,
} from '../../types';
import { describeFactory } from '../../utils';

export const finishingsCostPlugin: ProductCostPlugin = (
  { manufacturer, lineItems },
  { localize }
) => {
  const { finishings = {} } = manufacturer?.costs || {};
  const finishingItems: ProductCostLineItem[] = [];
  const describe = describeFactory(['quality', 'metal'], localize);

  lineItems.forEach((item) => {
    try {
      if (item.type === 'METAL') {
        const { quantity: weight, features } = item as ValueLineItem;
        const { metal, quality } = features;

        if (weight !== 0) {
          const matchingCosts = finishings[metal][quality].filter(
            ({ minWeight }) => minWeight <= weight
          );
          const finishingMatch = last(sortBy(matchingCosts, 'minWeight'));

          if (!finishingMatch) {
            throw new Error(
              `no finishing cost for ${weight}dwt ${describe(features)}`
            );
          }

          const { cost, minWeight } = finishingMatch;

          finishingItems.push({
            label: 'Finishing',
            type: 'FINISHING',
            description: `Finishing - > ${minWeight}dwt ${describe(features)}`,
            cost,
            quantity: 1,
            unitCost: cost,
            features,
          });
        }
      }
    } catch (error) {
      finishingItems.push({
        type: 'ERROR',
        label: 'Error',
        description: error.message as string,
      } as ErrorLineItem);
    }
  });

  return [...lineItems, ...finishingItems];
};
