import { CountryCode, CurrencyCode } from '../types';

export const supportedCurrencies = [
  {
    code: CurrencyCode.UnitedStatesDollar,
    country: CountryCode.UnitedStates,
    label: 'USD $',
  },
  {
    code: CurrencyCode.CanadianDollar,
    country: CountryCode.Canada,
    label: 'CAD $',
  },
  {
    code: CurrencyCode.BritishPound,
    country: CountryCode.GreatBritain,
    label: 'GBP￡',
  },
];

export const conversionRates: { [currency: string]: number } = {
  [CurrencyCode.BritishPound]: 0.785485,
  [CurrencyCode.CanadianDollar]: 1.33235,
  [CurrencyCode.UnitedStatesDollar]: 1,
};

export const currencyForCountry = (countryCode: CountryCode) => {
  if (countryCode === CountryCode.Canada) {
    return CurrencyCode.CanadianDollar;
  }
  if (countryCode === CountryCode.GreatBritain) {
    return CurrencyCode.BritishPound;
  }
  return CurrencyCode.UnitedStatesDollar;
};

export const DONATION_PERCENT = 0;
