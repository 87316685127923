import * as React from 'react';

interface GlobalContext {
  imgixBaseUrl: string;
}

const defaultValues: GlobalContext = {
  imgixBaseUrl: '',
};

export const GlobalContext = React.createContext({
  ...defaultValues,
});

interface GlobalContextProviderProps {
  defaults: GlobalContext;
}

export const GlobalContextProvider: React.FunctionComponent<GlobalContextProviderProps> = ({
  defaults,
  children,
}) => {
  const [values] = React.useState<GlobalContext>({
    ...defaultValues,
    ...defaults,
  });

  React.useEffect(() => {
    if (!values.imgixBaseUrl) {
      console.warn(
        'Tiary Components: No base imgix URL found. Renders wont show.'
      );
    }
  }, [values]);

  return (
    <GlobalContext.Provider value={{ ...values }}>
      {children}
    </GlobalContext.Provider>
  );
};
