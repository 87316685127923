import React from 'react'

import {
  StyledTiaryButtonFilled,
  StyledTiaryButtonMinimal,
  StyledTiaryButtonOutline,
} from './style'

interface TiaryButtonProps {
  className?: string
  toRoute?: string
  onClick?: () => void
  outline?: boolean
  minimal?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

export const TiaryButton: React.FunctionComponent<TiaryButtonProps> = ({
  children,
  toRoute,
  outline = false,
  minimal = false,
  ...rest
}) => {
  if (minimal) {
    return (
      <StyledTiaryButtonMinimal {...rest}>{children}</StyledTiaryButtonMinimal>
    )
  } else if (outline) {
    return (
      <StyledTiaryButtonOutline {...rest}>{children}</StyledTiaryButtonOutline>
    )
  } else {
    return (
      <StyledTiaryButtonFilled {...rest}>{children}</StyledTiaryButtonFilled>
    )
  }
}
