import { ShippingOptionCode } from "../types"

export const getTrackingUrl = ({
  shippingOptionCode,
  trackingCode,
}: {
  shippingOptionCode: ShippingOptionCode
  trackingCode: string
}) => {
  switch (shippingOptionCode) {
    case "UPU_FREE":
    case "UPU_EXPIDITED":
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingCode}`
    case "PUROLATOR_FREE":
    case "PUROLATOR_EXPIDITED":
      return `https://purolator.ts2000.net/Login/QuickTrack/?SearchOn=PuroPost&SearchFor=${trackingCode}`
    case "FEDEX_FREE":
    case "FEDEX_EXPIDITED":
    case "FEDEX_INTERNATIONAL":
    case "FEDEX_CANADA_FREE":
    case "FEDEX_CANADA_EXPIDITED":
      return `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingCode}`
    default:
      return ""
  }
}
