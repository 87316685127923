import { ProductCostPlugin, ProductCostLineItem } from '../../types';
import {
  ProductOptionType,
  DashboardProductStringOption,
} from '../../../../types';

const ENGRAVING_COST_PER_LETTER = 1;

export const metalSettingsCostPlugin: ProductCostPlugin = ({
  product,
  lineItems,
  configuration,
}) => {
  const metalSettingOptions: ProductCostLineItem[] = [];

  // Engraving pricing
  // Check if the product has the option to engrave it
  const productHasEngravingOption = product.options.find(
    (option) => option.type === ProductOptionType.Engraving
  ) as DashboardProductStringOption;

  if (productHasEngravingOption) {
    // If the product has the engraving option, get the engraving value
    const engravingValue = configuration[ProductOptionType.Engraving] as string;
    if (engravingValue) {
      // if we have an engraving value, add it to the line item cost
      metalSettingOptions.push({
        label: `Engraving`,
        type: 'ENGRAVING',
        description: `Value: ${engravingValue}`,
        cost: engravingValue.length * ENGRAVING_COST_PER_LETTER, // Since each letter on the engraving costs $1
        quantity: engravingValue.length,
        unitCost: ENGRAVING_COST_PER_LETTER,
      });
    }
  }

  return [...lineItems, ...metalSettingOptions];
};
