import { Timestamp } from '@firebase/firestore-types';

import { Address } from './address';
import { CurrencyCode } from './currencyCode';
import { OrderStatusCode } from './orderStatus';
import { ValidatedPriceRule } from './priceRule';
import { ShippingOptionCode } from './shippingOption';
import { AppliedDeposit } from './deposit';

export interface OrderManufacturing {
  provider: string;
  jobId?: string;
}

export interface OrderProduct {
  id: string;
  sku: string;
  price: number;
  configuration: any;
  packIndividually?: boolean;
  isInventoryItem?: boolean;
  dateAdded: Timestamp;
  bargainPrice:number;
}

export enum LineItem {
  Discount = 'discount',
  Donation = 'donation',
  Shipping = 'shipping',
  Subtotal = 'subtotal',
  Tax = 'tax',
  Total = 'total',
  Credit = 'credit',
}

export interface Pricing {
  [key: string]: number;
}

/**
 * @interface OrderDraftProposal
 * A more compact version of an order draft which contains
 * the minimum amount of information required to generate
 * a new order draft.
 */
export interface OrderDraftProposal {
  uid: string;
  email?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  promoCodes?: string[];
  shippingOptionCode?: ShippingOptionCode;
  stripeTokenId?: string;
}

export interface OrderDraft {
  dateUpdated: Timestamp;
  email: string;
  billingAddress: Address;
  shippingAddress: Address;
  id: string;
  pricing: Pricing;
  convertedPricing: Pricing;
  products: OrderProduct[];
  promoCodes: string[];
  currency?: CurrencyCode; // note - this is non-optional for all new orders
  requiredPayment?: {
    amount: number;
    conversionRate: number;
    currency: CurrencyCode;
  };
  shippingOption: {
    code: ShippingOptionCode;
    country: string;
    price: number;
  };
  validPriceRules: ValidatedPriceRule[];
  validDeposits: AppliedDeposit[];
  uid: string;
  user: string;
}

export enum ManufacturerCode {
  MasterCasting = 'MASTER_CASTING',
  RivaPrecision = 'RIVA_PRECISION',
  Valencia = 'VALENCIA',
  Inventory = 'INVENTORY',
  Goldman = 'GOLDMAN',
  Multiple = 'MULTIPLE',
}

// used for tracking the payment on the order
// stripe comes from stripe, paypal comes from paypal, none comes from them not required to pay (i.e $0 total)
export interface OrderPayment {
  amount: number;
  currency: string;
  id?: string;
  source?: 'stripe' | 'paypal' | 'none';
}

export interface Order extends OrderDraft {
  dateCreated: Timestamp;
  payment: OrderPayment;
  status: OrderStatusCode;
  trackingCode?: string;
  attribution?: string | null; // e.g., if the order was placed by a "point of sales" user
  manufacturer?: ManufacturerCode | null;
  manufacturing?: any; // depricated. use "manufacturer" prop
  _MasterCasting?: any;
  _RivaPrecision?: any;
}

export interface LegacyOrder {
  id: string;
  invoiceUrl: string;
}
