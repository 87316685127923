import {
  ContentfulProduct,
  ContentfulProductClassification,
  Product,
} from '../types';
import find from 'lodash/find';
import get from 'lodash/get';

const classificationNameForType = (
  classifications: ContentfulProductClassification[],
  type: string,
  defaultName = ''
) => {
  const match = classifications.find((c) => c.type === type);
  return match ? match.name : defaultName;
};

const DEFAULT_PRODUCT_CONTENT = {
  id: '',
  name: '',
  description: '',
  classifications: [],
  sku: '',
  slug: '',
  type: '',
  collection: '',
  category: '',
  fromPrice: 0,
  contextPhotos: [],
};

export const constructProduct = (
  content: ContentfulProduct,
  spec: any
): Product => ({
  ...DEFAULT_PRODUCT_CONTENT,
  ...spec,
  ...content,
  seoDescription: get(content, 'seoDescription.seoDescription', ''),
  type: classificationNameForType(content.classifications, 'Type'),
  collection: classificationNameForType(content.classifications, 'Collection'),
  category: classificationNameForType(content.classifications, 'Category'),
});

export const constructAllProducts = (
  contents: ContentfulProduct[],
  specs: any[]
) => {
  const products: Product[] = [];
  contents.forEach((content) => {
    const spec = find(specs, ({ sku }) => sku === content.sku);
    if (!spec) {
      return;
    }

    // The constructProduct function combines the spec and content and
    // transforms the result into an object that meets the requirements of the
    // "Product" interface.
    products.push(constructProduct(content, spec));
  });
  return products;
};

export default constructProduct;
