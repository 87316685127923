import { Deposit } from "../types"

interface ApplyDepositArgs {
  deposit: Deposit
  subtotal: number
}

// Returns the amount of the deposit applied to the purchase
export const applyDeposit = async ({ deposit, subtotal }: ApplyDepositArgs) => {
  try {
    let currentSubtotal = subtotal
    const depositRemaining = deposit.total - (deposit.consumed || 0)

    const depositApplied =
      depositRemaining < currentSubtotal ? depositRemaining : currentSubtotal
    currentSubtotal -= depositApplied

    return depositApplied
  } catch (error) {
    return 0
  }
}
