import {
  ProductCostPlugin,
  ValueLineItem,
  ProductCostLineItem,
  ErrorLineItem,
} from '../../types';
import {
  ProductOptionType,
  DashboardProductStoneOption,
} from '../../../../types';

export const stoneSettingsCostPlugin: ProductCostPlugin = (
  { product, lineItems, manufacturer },
  { localize = (a: any) => a }
) => {
  const stoneSettingOptions: ProductCostLineItem[] = [];
  try {
    lineItems.forEach((item) => {
      if (item.type === ProductOptionType.Stone) {
        const valueItem = item as ValueLineItem;
        const quantity = valueItem.quantity;
        const option = product.options.find(
          ({ name }) => valueItem.option === name
        ) as DashboardProductStoneOption;
        const stoneSetting = option.stoneSetting;
        if (!stoneSetting) {
          throw new Error(`stone setting not found for ${option.name}`);
        }
        const stoneSettingCost = manufacturer.costs.stoneSettings[stoneSetting];
        if (typeof stoneSettingCost !== 'number') {
          throw new Error(
            `cost not found for ${localize(stoneSetting)} stone setting`
          );
        }

        stoneSettingOptions.push({
          label: `${localize(stoneSetting)} Stone Setting`,
          type: 'STONE_SETTING',
          description: `${localize(stoneSetting)}`,
          cost: stoneSettingCost * quantity,
          quantity,
          unitCost: stoneSettingCost,
          features: { stoneSetting },
          option: item.option,
        });
      }
    });
  } catch (error) {
    stoneSettingOptions.push({
      type: 'ERROR',
      label: 'Error',
      description: error.message as string,
    } as ErrorLineItem);
  }
  return [...lineItems, ...stoneSettingOptions];
};
