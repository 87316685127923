import round from 'lodash/round';

import { ProductOptionType } from '../../../../types';
import { ProductCostPlugin, ValueLineItem } from '../../types';

export const nameCostPlugin: ProductCostPlugin = ({
  product,
  configuration,
  lineItems,
}) => {
  const nameOption = product.options.find((option) => option.name === 'TEXT_1');
  const multiplier =
    (nameOption && configuration.TEXT_1 && configuration.TEXT_1.length) || 1;
  return lineItems.map((item) => {
    if (
      item.type === ProductOptionType.Metal ||
      item.type === ProductOptionType.Stone
    ) {
      const valueItem = item as ValueLineItem;
      const quantity = round(valueItem.quantity * multiplier, 2);
      const cost = quantity * (valueItem.unitCost as number);
      return {
        ...item,
        quantity,
        cost,
      };
    }
    return item;
  });
};
