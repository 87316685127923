import * as React from 'react';

import { CustomizerFieldProps } from './index';
import {
  CustomizerFieldTemplate,
  CustomizerFieldTemplateProps,
} from './Template';

interface MaterialCustomizerFieldProps<ProductOptionType = any>
  extends CustomizerFieldProps,
    CustomizerFieldTemplateProps {
  describeMaterial: (value: ProductOptionType) => string;
  customizeLabel?: string;
}

export const MaterialCustomizerField: React.FunctionComponent<MaterialCustomizerFieldProps> = ({
  className,
  option,
  values,
  error,
  label,
  beforeContents,
  afterContents,
  describeMaterial,
}) => {
  return (
    <CustomizerFieldTemplate
      className={className}
      label={label || option.label}
      error={error}
      beforeContents={beforeContents}
      afterContents={afterContents}
    >
      <div className="tiary-customizer__material-field__description">
        {describeMaterial(values[option.name])}
      </div>
    </CustomizerFieldTemplate>
  );
};
