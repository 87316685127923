import { Timestamp } from '@firebase/firestore-types';
import format from 'date-fns/format';
import get from 'lodash/get';

interface MutatedTableValueOptions {
  mutate?: (value: any) => any;
  defaultValue?: string;
}

// If the path = '' (an empty string)
// use the root object
export const mutatedTableValue = (
  path: string,
  { mutate = (v: any) => v, defaultValue = '' }: MutatedTableValueOptions = {}
) => (obj: any) => {
  const value = path !== '' ? get(obj, path) : obj;
  return value ? mutate(value) : defaultValue;
};

export const tableValueDate = (path: string) =>
  mutatedTableValue(path, { mutate: (v: Date) => v.toLocaleString() });

export const tableValueDateFromFirebase = (path: string) =>
  mutatedTableValue(path, {
    mutate: (v: Timestamp) => format(new Date(v.seconds * 1000), 'yyyy/MM/dd'),
  });

export const tableValuePercent = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `${p * 100}%` : '0%'),
    defaultValue: '0%',
  });

export const tableValuePrice = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `$${p.toFixed(2)}` : '$0.00'),
    defaultValue: '$0.00',
  });

export const tableValuePriceFromStripe = (path: string) =>
  mutatedTableValue(path, {
    mutate: (p: number) => (p ? `$${(p / 100).toFixed(2)}` : '$0.00'),
    defaultValue: '$0.00',
  });

export const tableRowData = (obj: any, fields: any[]) => {
  const rowData: { [key: string]: string } = {};
  fields.forEach(({ label, value }) => {
    if (!label) {
      return;
    }
    if (typeof value === 'string') {
      rowData[label] = get(obj, value);
    } else if (typeof value === 'function') {
      rowData[label] = value(obj, { label: '' });
    } else {
      rowData[label] = '';
    }
  });
  return rowData;
};
