import * as React from 'react';

import { PlainFieldSelect } from '../../../shared/PlainFieldSelect';
import { CustomizerFieldProps } from './index';
import {
  CustomizerFieldTemplate,
  CustomizerFieldTemplateProps,
} from './Template';

interface SelectCustomizerFieldProps<ProductOptionType = any>
  extends CustomizerFieldProps<any, ProductOptionType>,
    CustomizerFieldTemplateProps {
  getLabel: (optionValue: any) => string;
  getKey?: (optionValue: any) => string | undefined;
  placeholder?: string;
  selectOptions: ProductOptionType[];
}

export const SelectCustomizerField: React.FunctionComponent<SelectCustomizerFieldProps> = ({
  className,
  selectOptions,
  option,
  values,
  setFieldValue,
  error,
  placeholder,
  getLabel,
  getKey = (o: typeof option) => JSON.stringify(o),
  label,
  beforeContents,
  afterContents,
}) => {
  const onValueChange = (nextKey: string): void => {
    const optionForKey = option.values.find((o: any) => getKey(o) === nextKey);
    setFieldValue(option.name, optionForKey);
  };

  React.useEffect(() => {
    const value = values[option.name];
    const key = getKey(value);
    if (key) {
      onValueChange(key);
    }
  }, []);

  const labeledOptions = selectOptions.map((optionValue: any) => ({
    label: getLabel(optionValue),
    value: getKey(optionValue),
  }));

  return (
    <CustomizerFieldTemplate
      className={className}
      label={label || option.label}
      error={error}
      beforeContents={beforeContents}
      afterContents={afterContents}
    >
      <PlainFieldSelect
        name={option.name}
        title={option.label}
        placeholder={placeholder}
        options={labeledOptions}
        value={getKey(values[option.name])}
        onValueChange={onValueChange}
      />
    </CustomizerFieldTemplate>
  );
};
