import { ChainStyle } from '../types';

const CHAIN_STYLES = {
  'ROL-1': 'Rolo',
  STY2: 'Style 2',
};

export const describeChainStyle = ({ type }: { type?: ChainStyle } = {}) =>
  type ? CHAIN_STYLES[type] : '';

export const describeChainLength = ({
  type,
}: { type?: string | number } = {}) => `${type}" with 1” shortening ring`;
export const describeRingSize = ({ type }: { type?: string | number } = {}) =>
  `${type}`;
export const describeBraceletSize = ({
  type,
}: { type?: string | number } = {}) => `${type}`;

export const describeCaratWeight = ({type}:{type?: string | number}={})=> `${type}`;
