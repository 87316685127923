import { Timestamp } from "@firebase/firestore-types"
import { OrderStatusCode } from "./orderStatus"

export enum TouchpointType {
  Email = "EMAIL",
}

export enum TouchpointTrigger {
  OrderCommitted = "ORDER_COMMITTED",
  OrderShipped = "ORDER_SHIPPED",
  OrderStatusUpdate = "ORDER_STATUS_UPDATE",
}

export interface Touchpoint {
  id?: string
  type: TouchpointType
  user?: string
  order?: string
  orderStatus?: OrderStatusCode
  date: Timestamp
  trigger: TouchpointTrigger
  description?: string
}

export interface EmailTouchpoint extends Touchpoint {
  type: TouchpointType.Email
  email: string
}
