import { getOptionLineItems } from '../getOptionLineItems';
import { describeFactory } from '../../utils';
import { OptionCostCalculator } from '../../types';
import { MaterialType } from '../../../../types';

export const getCostForChainOption: OptionCostCalculator = (
  { option, manufacturer, configuration },
  bag
) => {
  const { materials, localize = (a: any) => a } = bag;
  return getOptionLineItems({
    option,
    manufacturer,
    configuration,
    materials,
    describe: describeFactory(['size', 'style', 'quality', 'metal'], localize),
    predicate: (config) => ({
      material: MaterialType.Chain,
      size: `${config.type}_INCHES`,
      style: config.style || 'ROLO',
      quality: config.quality,
    }),
  });
};
