import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

import {
  MetalConfig,
  ProductConfiguration,
  StoneConfig,
  ProductOptionValue,
} from '../types';
import { describeMetal } from './metal';
import {
  describeBraceletSize,
  describeChainLength,
  describeRingSize,
  describeCaratWeight
} from './size';
import { describeStone } from './stone';
import { describeQuantity } from './quantity';

interface ConfigurationDetail {
  label: string;
  value: string;
}

const DEFAULT_TRANSFORM = (details: ConfigurationDetail[]) =>
  details.map(({ label, value }) => `${label}: ${value}`);

export const describeConfiguration = (
  configuration: ProductConfiguration = {},
  transform: (details: ConfigurationDetail[]) => any = DEFAULT_TRANSFORM
) => {
  const details = Object.keys(configuration)
    .sort()
    .map((name) => {
      const valueObj = configuration[name];
      const detail: ConfigurationDetail = {
        label: startCase(toLower(name)),
        value: '',
      };

      if (name.includes('STONE')) {
        detail.value = describeStone(valueObj as StoneConfig);
      } else if (name.includes('METAL')) {
        detail.value = describeMetal(valueObj as MetalConfig);
      } else if (name.includes('TEXT')) {
        detail.value = `"${valueObj}"`;
      } else if (name.includes('CHAIN_LENGTH')) {
        detail.value = describeChainLength(valueObj as ProductOptionValue);
      } else if (name.includes('RING_SIZE')) {
        detail.value = describeRingSize(valueObj as ProductOptionValue);
      } else if (name.includes('BRACELET_SIZE')) {
        detail.value = describeBraceletSize(valueObj as ProductOptionValue);
      } else if (name.includes('ENGRAVING')) {
        detail.value = `"${valueObj}"`;
      } else if (name.includes('QUANTITY')) {
        detail.value = describeQuantity(valueObj as ProductOptionValue);
      } else if (name.includes('CARAT_WEIGHT')){
        detail.value = describeCaratWeight(valueObj as ProductOptionValue);
      }

      return detail;
    });
  return transform(details);
};
