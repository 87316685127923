import find from 'lodash/find';
import {
  ProductConfiguration,
  Manufacturer,
  DashboardProductOption,
  TiaryMaterial,
} from '../../../types';

import {
  OptionLineItemsPredicate,
  OptionLineItemsDescribe,
  OptionLineItemsQuantify,
  OptionLineItemsExpand,
  ProductCostLineItem,
  ErrorLineItem,
  ValueLineItem,
} from '../types';

export const getOptionLineItems = ({
  option,
  manufacturer,
  configuration,
  materials,
  predicate,
  describe,
  quantify = () => 1,
  expand = (option) => [option],
}: {
  option: DashboardProductOption;
  manufacturer: Manufacturer;
  configuration: ProductConfiguration;
  materials: TiaryMaterial[];
  predicate: OptionLineItemsPredicate;
  describe: OptionLineItemsDescribe;
  quantify?: OptionLineItemsQuantify;
  expand?: OptionLineItemsExpand;
}): ProductCostLineItem[] => {
  try {
    const { name, label } = option;
    // 1 - GET THE CONFIG
    // check that the product configuration is valid for this option
    const optionConfig = configuration[name];
    if (!optionConfig) {
      throw new Error(`Configuration set missing or invalid for ${name}`);
    }

    // TODO If this is the "Base Metal" then we must also calculate the cost
    // of the findings

    // 2 - EXPAND INTO MATERIALS
    // Sometimes a single option will expand into multiple materials and line
    // items. For example, a stone option refers to a number of different stones
    // in different sizes and settings.
    return expand(option).map((spec) => {
      // 3 - GET THE MATERIAL ID
      // find the material based on the predicate
      const features = predicate(optionConfig, spec);
      const material = find(materials, features);
      if (!material || !material.id) {
        throw new Error(`Material could not be found: ${describe(features)}`);
      }

      // 4 - GET THE MANUFACTURER COST
      // find the cost from the manufacturer
      const unitCost = manufacturer.costs.materials[material.id];
      if (typeof unitCost !== 'number') {
        throw new Error(
          `Cost not found for ${describe(features)} from ${manufacturer.name}`
        );
      }

      // 5 - GET THE QUANTITY
      // TODO if there is a name option we must also multiply by the number
      // of letters
      const quantity = quantify({
        optionName: name,
        configuration,
        spec,
      });

      return {
        label,
        features: material,
        quantity,
        option: option.name,
        description: describe(features),
        type: option.type,
        cost: unitCost * quantity,
        unitCost: unitCost,
      } as ValueLineItem;
    });
  } catch (error) {
    return [
      {
        type: 'ERROR',
        description: error.message as string,
      },
    ] as ErrorLineItem[];
  }
};
