import { MetalConfig, MetalQuality, MetalType } from '../types';

const METAL_TYPES = {
  SILVER: 'Silver',
  GOLD_ROSE: 'Rose Gold',
  GOLD_WHITE: 'White Gold',
  GOLD_YELLOW: 'Yellow Gold',
};

const METAL_QUALITIES = {
  KARAT_10: '10K',
  KARAT_14: '14K',
  KARAT_18: '18K',
  STERLING: '925 Sterling',
};

export const describeMetalType = (type: MetalType) => METAL_TYPES[type] || '';
export const describeMetalQuality = (quality: MetalQuality) =>
  METAL_QUALITIES[quality] || '';

const defaultMetalTemplate = ({ type, quality }: MetalConfig) =>
  `${quality} ${type}`;

export const describeMetal = (
  metalObj: MetalConfig,
  { template = defaultMetalTemplate } = {}
) => {
  if (!metalObj) {
    return '';
  }
  const type = describeMetalType(metalObj.type) as MetalType;
  const quality = describeMetalQuality(metalObj.quality) as MetalQuality;
  return template({ quality, type });
};
