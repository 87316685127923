import isEmpty from 'lodash/isEmpty';

import { MaterialType, DashboardProductStoneOption } from '../../../../types';
import { getOptionLineItems } from '../getOptionLineItems';
import { describeFactory } from '../../utils';
import { OptionCostCalculator } from '../../types';
import { nameCostPlugin } from '../../plugins/name';
import { stoneSettingsCostPlugin } from '../../plugins/stoneSettings';

export const getCostForStoneOption: OptionCostCalculator = (
  { option, product, manufacturer, configuration },
  bag
) => {
  const { materials, localize = (a: any) => a } = bag;
  let lineItems = getOptionLineItems({
    option,
    manufacturer,
    configuration,
    materials,
    describe: describeFactory(['size', 'quality', 'type'], localize),
    expand: ({ values }) => values.sizes,
    quantify: ({ spec }) => spec.quantity,
    predicate: ({ type, quality }, { size }) => ({
      material: MaterialType.Stone,
      type,
      quality,
      size,
    }),
  });

  const stoneOption = option as DashboardProductStoneOption;
  if (!stoneOption?.values?.sizes || isEmpty(stoneOption.values.sizes)) {
    lineItems.push({
      type: 'ERROR',
      label: 'Missing Sizes',
      description: `There are no sizes set for option "${stoneOption.name}"`,
    });
  }

  const plugins = [nameCostPlugin, stoneSettingsCostPlugin];

  plugins.forEach((applyPlugin) => {
    lineItems = applyPlugin(
      {
        product,
        lineItems,
        manufacturer,
        configuration,
      },
      bag
    );
  });

  return lineItems;
};
