import * as React from 'react';

import { CustomizerFieldProps } from '.';
import {
  CustomizerFieldTemplate,
  CustomizerFieldTemplateProps,
} from './Template';

export enum StringCaseTransform {
  Upper = 'UPPER',
  Lower = 'LOWER',
  None = 'NONE',
}

export const transformValueFactory = ({
  regexPattern,
  maxLength,
  transformCase,
}: {
  regexPattern?: string;
  maxLength?: number;
  transformCase?: StringCaseTransform;
}) => (value: string) => {
  let nextValue = value;

  if (transformCase && transformCase === StringCaseTransform.Upper) {
    nextValue = nextValue.toUpperCase();
  }

  if (transformCase && transformCase === StringCaseTransform.Lower) {
    nextValue = nextValue.toLowerCase();
  }

  if (regexPattern) {
    const regExp = new RegExp(regexPattern, 'g');
    const matching = regExp.exec(nextValue) || [];
    nextValue = matching.join('');
  }

  if (maxLength) {
    nextValue = nextValue.slice(0, maxLength);
  }

  return nextValue;
};

interface TextCustomizerFieldProps
  extends CustomizerFieldProps,
    CustomizerFieldTemplateProps {
  transformValue?: (value: string) => string;
  placeholder?: string;
  regexPattern?: string;
}

export const TextCustomizerField: React.FunctionComponent<TextCustomizerFieldProps> = ({
  className,
  option,
  values,
  setFieldValue,
  error,
  placeholder,
  transformValue = (value: string) => value,
  label,
  beforeContents,
  afterContents,
}) => {
  const setValue = (nextValue: string): void => {
    setFieldValue(option.name, nextValue);
  };

  const setValueFactory = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    const nextValue = transformValue(e.target.value);
    setValue(nextValue);
  };

  // if the max length is 1, then its a 1 character input
  const isOneCharacterInput = (option.maxLength || 0) > 1;

  // If the max length is 1, that means its a letter/character input
  // we can put it in the afterContents so it looks cleaner
  return (
    <CustomizerFieldTemplate
      className={className}
      label={label || option.label}
      error={error}
      beforeContents={beforeContents}
      afterContents={
        isOneCharacterInput ? (
          afterContents
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            value={values[option.name]}
            onChange={setValueFactory()}
          />
        )
      }
    >
      {isOneCharacterInput ? (
        <input
          type="text"
          placeholder={placeholder}
          value={values[option.name]}
          onChange={setValueFactory()}
        />
      ) : null}
    </CustomizerFieldTemplate>
  );
};
