import * as React from 'react';

import { isEmpty } from '../../../utils/lodash';
interface CustomizerContextValue<
  ConfigurationType = any,
  ProductOptionType = any
> {
  configuration: ConfigurationType;
  errors: { [fieldName: string]: string };
  isSubmitting: boolean;
  price?: number;
  setConfiguration: (nextConfig: ConfigurationType) => void;
  setFieldValue: (fieldName: string, value: ProductOptionType) => void;
  handleSubmit: () => Promise<void>;
}

const defaultValue: CustomizerContextValue<any, any> = {
  configuration: {},
  errors: {},
  isSubmitting: false,
  setConfiguration: () => {},
  setFieldValue: () => {},
  handleSubmit: async () => {},
};

export const CustomizerContext = React.createContext(defaultValue);

interface CustomizerContextProviderProps<ConfigurationType = any> {
  initialConfiguration: ConfigurationType;
  submit: (configuration: ConfigurationType) => Promise<any>;
  validate: (configuration: ConfigurationType) => Promise<any>;
  getPrice: (values: ConfigurationType) => number | undefined;
}

export const CustomizerContextProvider: React.FunctionComponent<CustomizerContextProviderProps> = ({
  initialConfiguration = {},
  submit,
  validate,
  children,
  getPrice,
}) => {
  const [configuration, setConfiguration] = React.useState(
    initialConfiguration
  );
  const [price, setPrice] = React.useState(getPrice(initialConfiguration));
  const [errors, setErrors] = React.useState<any>({});
  const [isSubmitting, setSubmitting] = React.useState(false);

  // Run the validation against the configuration & set the errors
  /*
  const runValidation = async (
    configToValidate: ProductConfiguration
  ): Promise<void> => {
    const validationResult = await validate(configToValidate);
    if (validationResult.errors && !isEmpty(validationResult.errors)) {
      setErrors(validationResult.errors);
      setSubmitting(false);
    } else if (isEmpty(validationResult.errors)) {
      setErrors({});
    }
  };
  */

  const updateConfiguration = (
    nextConfiguration: typeof configuration
  ): void => {
    // console.log('Updating config', nextConfiguration, configuration);
    // runValidation(nextConfiguration);
    const nextPrice = getPrice(nextConfiguration);
    setPrice(nextPrice);
    setConfiguration(nextConfiguration);
  };

  const setFieldValue = (fieldName: string, value: any): void => {
    updateConfiguration({ ...configuration, [fieldName]: value });
  };

  const handleSubmit = async (): Promise<void> => {
    setSubmitting(true);
    const validationResult = await validate(configuration);
    if (validationResult.errors && !isEmpty(validationResult.errors)) {
      setErrors(validationResult.errors);
      setSubmitting(false);
      return;
    }
    const submissionResult = await submit(configuration);
    if (submissionResult && submissionResult.errors) {
      setErrors(submissionResult.errors);
    }
    setSubmitting(false);
    return;
  };

  return (
    <CustomizerContext.Provider
      value={{
        configuration,
        price,
        setConfiguration: updateConfiguration,
        errors,
        setFieldValue,
        handleSubmit,
        isSubmitting,
      }}
    >
      {children}
    </CustomizerContext.Provider>
  );
};
