import * as React from 'react';

import { CustomizerFieldProps } from './fields/index';
import { CustomizerLayoutProps } from '../index';
import { CustomizerContextProvider } from './context/CustomizerContext';
import { TiaryRendererProps, TiaryRenderer } from '../TiaryRenderer';

export * from './parts';
export * from './layouts';
export * from './fields';
export * from './context';

interface TiaryProductCustomizerProps<
  ProductType = any,
  ProductConfigType = any,
  ProductSpecType = any,
  ProductOptionType = any
> {
  className?: string;

  product: ProductType;
  initialConfiguration?: ProductConfigType;
  getDefaultConfiguration: (spec: ProductSpecType) => ProductConfigType;

  LayoutComponent: React.FunctionComponent<
    CustomizerLayoutProps<
      ProductType,
      ProductConfigType,
      ProductOptionType,
      ProductSpecType
    >
  >;
  RendererComponent?: React.FunctionComponent<TiaryRendererProps>;

  getField?: (
    option: ProductOptionType,
    allOptions: ProductOptionType[]
  ) => React.FunctionComponent<
    CustomizerFieldProps<ProductType, ProductOptionType>
  > | null;
  // TODO?: Switch back to
  getModal?: (
    option: ProductOptionType
  ) => React.FunctionComponent<
    CustomizerFieldProps<ProductType, ProductOptionType>
  > | null;

  onSubmit: (values: ProductConfigType) => Promise<any>;
  onValidate: (configuration: ProductConfigType) => Promise<any>;

  getPrice: (values: ProductConfigType) => number | undefined;
  sortOptions?: (options: ProductOptionType[]) => ProductOptionType[];
  getOrderedRenderOptions?: (
    options: ProductOptionType[]
  ) => ProductOptionType[];
}

export const TiaryProductCustomizer: React.FunctionComponent<TiaryProductCustomizerProps> = ({
  className,
  getDefaultConfiguration,
  getField,
  getOrderedRenderOptions,
  getPrice,
  initialConfiguration,
  LayoutComponent,
  onSubmit,
  onValidate,
  product,
  RendererComponent = TiaryRenderer,
  sortOptions,
}) => {
  return (
    <CustomizerContextProvider
      initialConfiguration={
        initialConfiguration || getDefaultConfiguration(product)
      }
      submit={onSubmit}
      validate={onValidate}
      getPrice={getPrice}
    >
      <LayoutComponent
        className={className}
        product={product}
        getField={getField}
        sortOptions={sortOptions}
        getDefaultConfiguration={getDefaultConfiguration}
        getOrderedRenderOptions={getOrderedRenderOptions}
        RendererComponent={RendererComponent}
      />
    </CustomizerContextProvider>
  );
};
