import { OrderStatusCode } from "../types"
import { startCase } from "lodash"

const labelByStatus: { [status: string]: string } = {
  CASTING: "Casting & Stone Setting",
  STONE_SETTING: "Casting & Stone Setting",
  [OrderStatusCode.InProduction]: "Processing",
  [OrderStatusCode.Wax]: "Wax",
  [OrderStatusCode.CastingAndStoneSetting]: "Casting & Stone Setting",
  [OrderStatusCode.Polishing]: "Polishing",
  [OrderStatusCode.QualityControl]: "Quality Control",
  [OrderStatusCode.Shipped]: "Shipped",
  [OrderStatusCode.Processing]: "Processing",
  [OrderStatusCode.Cancelled]: "Cancelled",
}

export const describeOrderStatus = (status: OrderStatusCode) =>
  labelByStatus[status] || startCase((status || "").toLowerCase())
