import * as React from 'react';
import Styled from 'styled-components';
import { RendererLayout } from '../../types/renderer';

import Layer from './Layer';

const Container = Styled.div`
  visibility: hidden;
  overflow: hidden;
  height: 0;
  width: 0;
`;

interface PreloaderProps<ProductSpecType = any> {
  productSpec: ProductSpecType;
  layout: RendererLayout;
}

const Preloader: React.FunctionComponent<PreloaderProps> = ({
  productSpec,
  layout,
}) => (
  <Container>
    {productSpec.options.map((option: any) => {
      if (!option || !['METAL', 'STONE'].includes(option.type)) {
        return null;
      }
      const rendersForOption = [] as Array<string | undefined>;
      return option.values.map(
        ({ type, render }: { type: any; render: any }) => {
          if (rendersForOption.includes(render)) {
            return null;
          }
          rendersForOption.push(render);
          return (
            <Layer
              key={`preload-${type}`}
              path={render || ''}
              layout={layout}
              renderConstraints={productSpec.renderConstraints}
              hide={true}
            />
          );
        }
      );
    })}
  </Container>
);

export default Preloader;
