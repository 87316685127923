import { ProductConfiguration, ProductSpec, ProductOptionType } from '../types';
import { ProductOptionValue } from '../types/index';
import { isGoldmanProduct } from './getCostForProduct';
import { getDefaultConfiguration } from './getDefaultConfiguration';
import * as _ from 'lodash';

// Use this function to get the price of an item with a configuration
export const getPriceForConfiguration = ({
  product,
  configuration = {},
}: {
  product: ProductSpec;
  configuration?: ProductConfiguration;
}): number => {
  if (!product) {
    return 0;
  }

  let quantityMultiplier = 1;

  // Get the product base price (NOTE: This is in USD)
  let productPrice = product.basePrice;
  const productOptions = product.options || [];

  const configForProduct = {
    ...getDefaultConfiguration(product),
    ...configuration,
  };

  // console.log('\n\ngetPriceForConfiguration', product.sku, configForProduct);

  Object.keys(configForProduct).forEach((configOption) => {
    const configMatch = configForProduct[configOption];
    if (!configMatch || typeof configMatch === 'string') {
      return;
    }
    const { type, quality } = configMatch;

    const matchingOption = productOptions.find(
      (option) => option.name === configOption
    );

    if (!matchingOption) {
      throw new Error(
        `Product option ${configOption} not found on ${product.sku}`
      )
    } else if (
      matchingOption.type === 'STONE' ||
      matchingOption.type === 'METAL' ||
      matchingOption.type === 'CHAIN_LENGTH' 
    ) {

      const optionValues = matchingOption.values || [];
      const valueMatch = optionValues.find(
        (value) => value.type === type && value.quality === quality
      );

      if (!valueMatch) {
        throw new Error(
          `Product value ${type} - ${quality} for option ${configOption} not found on ${product.sku}.`
        );
      }

      if (valueMatch && valueMatch.price) {
        productPrice += valueMatch.price || 0;
      }

      if (product.nameOption) {
        const nameValue = configForProduct[product.nameOption];
        if (nameValue && nameValue.length) {
          const nameLength = nameValue.length > 6 ? nameValue.length : 6; // flat pricing below 6 characters
          productPrice += (nameLength - 6) * (valueMatch.letterPrice || 0);
        }
      }
      
      // If we have an engraving on the config, and we're on the metal option
      // apply the engraving pricing
      if (
        configForProduct[ProductOptionType.Engraving] &&
        matchingOption.type === 'METAL'
      ) {
        const engravingText = configForProduct[
          ProductOptionType.Engraving
        ] as string;
        if(!isGoldmanProduct(product.sku)){
          //ENGRAVING CHARGES ONLY FOR MCC PRODUCTS
          productPrice += engravingText.length * 1;
        }
      }
    }else if(matchingOption.type === 'CARAT_WEIGHT' || 
             matchingOption.type === 'RING_SIZE'){
      //carat weight will always have a type and a price so just add the price associated with it. 
      
      const optionValues = matchingOption.values || [];
      const valueMatch = _.find(optionValues,{type:type});
      if(valueMatch && valueMatch.price){
        productPrice+=valueMatch.price;
      }
    } else if (matchingOption.type === 'QUANTITY') {
      const optionValues = matchingOption.values || [];
      const valueMatch = optionValues.find(
        (value: ProductOptionValue) => value.type === type
      );
      if (valueMatch) {
        quantityMultiplier = valueMatch.multiplier || 1;
      }
    }
  });

  //now that we have offsets we can search for them
  if (product.offsets){
    //offsets by design have 3 attributes only
    const offsetConfig:any = {
      METAL_1: _.pick(configuration.METAL_1,['type','quality']),
    }

    //for pendants and studs there won't be ring size options
    if(_.find(product.options,{type:"RING_SIZE"})){
      offsetConfig.RING_SIZE= _.pick(configuration.RING_SIZE,['type'])
    }


    //for bands there won't be a center stone
    if (_.find(product.options,{type:"CARAT_WEIGHT"})){
      //in case the object has a center stone
      offsetConfig.CARAT_WEIGHT = _.pick(configuration.CARAT_WEIGHT,['type'])
    }
    const matchingOffset = _.find(product.offsets,{configuration:offsetConfig});

    if (matchingOffset){
      productPrice += matchingOffset.offsetPrice;
    }

  }
  const finalPrice = parseFloat((productPrice * quantityMultiplier).toFixed(2));
  

  // console.log('Final price for ', product.sku, finalPrice);

  return finalPrice;
};
